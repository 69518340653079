import React, {useEffect, useState} from 'react'
import BreadCrumb from "../components/common/breadcrumb/Breadcrumb";
import imgCartBuy from '../assets/img/cart/Group.png';
import '../assets/scss/cart.scss';
import {Link, useHistory} from 'react-router-dom';
import {Button, Modal} from "react-bootstrap";
import $ from  'jquery' ;
import icon_minus from '../assets/img/minus.png';
import icon_plus from '../assets/img/plus icon.png';
import {useDispatch, useSelector} from 'react-redux';
import {CartApiService, CouponApiService} from "../ultils/EndPoint";
import {GetAllDataCart} from '../actions/Cart';
import CartEmpty from './CartEmpty';
import {convertDateTime, formatCurrency, getImageMedia, onlyNumber} from '../helpers/Function';
import Form from "react-bootstrap/Form";
import {websiteActions} from "../actions";

function Cart() {
    // set const 
    const state = useSelector(state => state.todoProduct)
    const auth = useSelector(state => state.AuthMeRoot.auth)
    let pointMe = auth.Point
    var dataAll = [];
    state.cartData.products && state.cartData.products.map((cart, index) => {
            dataAll.push({
                quantity: cart.quantity,
                product_id: cart.id,
                selected: true,
                coupon_id: cart.coupon_id,
                real_price: cart.real_price,
                origin_price: cart.origin_price,
                coupon_value: cart.coupon_value,
                product_point : cart.product_point,
                is_available: cart.is_available
            })
        }
    )

    // useEffect(() => {
    //     dispatch(websiteActions.setChat(false))
    //     dispatch(websiteActions.setFooter(false))
    // })
    useEffect(() => {
        dispatch(websiteActions.setFooter(false))
    }, [])
    const [dataCart, setDataCart] = useState([])
    const [quantity, setQuantity] = useState([])
    // set for modal
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setDataQuantity(state.cartData.products)
        setShow(false);
    }
    const [cartOneItemId, setCartOneItemId] = useState('');
    const [total, setTotal] = useState({})
    const [totalPoint, setTotalPoint] = useState(0)

    const handleShow = (e) => {
        setShow(true)
        setCartOneItemId(e)
    };

    const [showVoucher, setShowVoucher] = useState(false);
    const [couponList, setCouponList] = useState([])
    const [couponListNotUse, setCouponListNotUse] = useState([])
    const [productCoupon, setProductCoupon] = useState(null)
    const [showStatus, setShowStatus] = useState(false);
    const [message, setMessage] = useState(false);
    const handleCloseStatus = () => {
        setShowStatus(false)
    };
    const showAlert = (message) => {
        setMessage(message);
        setShowStatus(true);
    }

    // get data of cart
    async function setDataQuantity(carts) {
        let data = []
        if (carts && carts.length) {
            carts.map(cartProduct => {
                data.push({'id': cartProduct.id, 'quantity': cartProduct.quantity})
            })
            await setQuantity(data)
        }
    }
    async function calculateTotal(data = []) {
        let checked = document.querySelectorAll('input[type="checkbox"].selectedId:checked')
        let all = {
            'provisional': 0,
            'discount': 0,
            'total_payment': 0,
        }
        let totalPointInCart = 0
        let dataCartAll = data.length ? data : dataAll

        dataCartAll.map((cart, index) => {
            checked.forEach(item => {
                let input = item.getAttribute('index')
                if(dataAll[input].product_id === cart.product_id){
                    all['provisional'] +=  cart.origin_price
                    all['discount'] +=  cart.coupon_value
                    if (cart.product_point > 0){
                        totalPointInCart +=  cart.product_point * cart.quantity
                    }
                }
            })
        })

        all['total_payment'] = all['provisional'] - all['discount']
        setTotal(all)
        setTotalPoint(totalPointInCart)
    }
    useEffect(async () => {
        if (state.cartData) {
            await setDataCart(state.cartData.products)
            setDataQuantity(state.cartData.products)
            await ChangeSelect()
            await ChangeSelectMobile()
        }
    }, [state.cartData.products])

    let totalProductsInCart = 0
    dataCart && dataCart.map(number =>
        totalProductsInCart += number.quantity
    )

    const tokenUser = localStorage.getItem('User-token')
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if(!tokenUser) {
            dispatch(websiteActions.setFooter(false))
            // history.replace('/')
        }
    })

    
    // set checked
    useEffect(() =>{
        $(function () {
            $(document).ready(function () {
                $('#selectall').click(function () {
                    $('.selectedId').prop('checked', this.checked);
                });
                $('#selectall-mobile').click(function () {
                    $('.selectedIdMobile').prop('checked', this.checked);
                });

                $('.selectedId').change(function () {
                    var check = ($('.selectedId').filter(":checked").length === $('.selectedId').length);
                    $('#selectall').prop("checked", check);
                });
                $('.selectedIdMobile').change(function () {
                    var check = ($('.selectedIdMobile').filter(":checked").length === $('.selectedIdMobile').length);
                    $('#selectall-mobile').prop("checked", check);
                });
            });
        })
    })

    const handlePurcharse = () => {
        if (totalPoint > auth.Point){
            showAlert("Bạn không đủ điểm để mua hàng")
            return
        }
        let valores = []
        let checkboxes = document.querySelectorAll('input[type="checkbox"].selectedId:checked')
        
        checkboxes.forEach(input => {
            let valor = input?.defaultValue || input?.value;
            valores.push(valor);
        })

        let isBuy = true;
        for (let i = 0; i < valores.length; i++) {
            let productId = parseInt(valores[i])
            let product = dataAll.find(item => item.product_id === productId)
            if (!product) {
                isBuy = false;
                break;
            }
            if (!product.is_available) {
                isBuy = false;
                break;
            }
        }
        if (!isBuy) {
            showAlert('Có sản phẩm không thể đặt hàng!')
            return;
        }
        if (valores.length){
            history.push('/thanh-toan?payment-products=['+valores.join()+']')
        }else{
            showAlert("Chưa chọn sản phẩm nào trong giỏ hàng để thanh toán!")
        }
    }

    // func cart not auth check
    function cartNotAuth(res) {
        if(res.status === true){
            dispatch(GetAllDataCart(res.data));
        }
    }

    // current count before total count
    function TotalPrice(price,tonggia){
        return Number(price * tonggia).toLocaleString('en-US');
    }

    let TotalCurrPriceCart = 0;
    dataCart && dataCart.map(check =>
        TotalCurrPriceCart+= check.quantity * check.product_price
    )

    function checkQuantityStock(quantity, cart) {
        if (cart.product_stock < quantity) {
            showAlert('Số lượng sản phẩm không có đủ trong kho!');
            return false
        }
        return true
    }
    const DecreaseQuantity = (cart) => {
        if (!cart.is_available) {
            showAlert('Sản phẩm hiện không tồn tại hoặc không kinh doanh! Vui lòng chọn một sản phẩm khác');
            return;
        }
        if (parseInt(cart.quantity) === 1 ) {
            handleShow(cart)
            return true
        }
        dataAll.map(quantity => {
            if(quantity.product_id === cart.id) {
                if(cart.quantity > 1) {
                    quantity.quantity--;
                }
            }
        })
        if(tokenUser){
            CartApiService.postCart(dataAll).then(res => {
                cartNotAuth(res);
            })
        }else {
            CartApiService.postCartNotAuth(dataAll).then(res => {
                cartNotAuth(res);
                localStorage.setItem("cart_not_auth", JSON.stringify(res.data.products));
            })
        }
    }

    const IncreaseQuantity = (cart) => {
        if (!cart.is_available) {
            showAlert('Sản phẩm hiện không tồn tại hoặc không kinh doanh! Vui lòng chọn một sản phẩm khác');
            return;
        }

        let isUpdate = true
        let point = 0;
        let keySelect = -1;

        dataAll.map(async (quantity, key) => {
            if (quantity.product_id === cart.id) {
                quantity.quantity++;
                keySelect = key
                isUpdate = checkQuantityStock(quantity.quantity, cart)
            }

            if (quantity.product_point !== 0){
                point += quantity.product_point * quantity.quantity
            }
        })

        if (point > pointMe){
            dataAll[keySelect].quantity--
            showAlert('Không đủ điểm để đổi sản phẩm!')
            return
        }
       
        if (isUpdate) {
            if(tokenUser){
                CartApiService.postCart(dataAll).then(res => {
                    cartNotAuth(res);
                })
            }else {
                CartApiService.postCartNotAuth(dataAll).then(res => {
                    cartNotAuth(res);
                    localStorage.setItem("cart_not_auth", JSON.stringify(res.data.products));
                })
            }
        }
    }
    
    const updateQuantity = (evt, cart) => {
        if (!parseInt(evt.target.value)) {
            handleShow(cart)
            return true
        }

        let isUpdate = true
        let point = 0;
        let keySelect = -1;
        let quantityOld = 0
        let cartIdChange = 0
        dataAll.map((quantity,key) => {
            if (quantity.product_id === cart.id) {
                quantityOld = quantity.quantity
                cartIdChange = cart.id
                quantity.quantity = parseInt(evt.target.value);
                keySelect = key
                isUpdate = checkQuantityStock(quantity.quantity, cart)
            }

            if (quantity.product_point !== 0){
                point += quantity.product_point * quantity.quantity
            }
        })
        if (!cart.is_available) {
            dataAll.map(quantity => {
                if (quantity.product_id === cart.id) {
                    quantity.quantity = quantityOld;
                }
            })
            dataAll[keySelect].quantity = quantityOld
            $('#input_quantity_' + cartIdChange).val(quantityOld)
            $('#input_quantity_mobile_' + cartIdChange).val(quantityOld)
            showAlert('Sản phẩm hiện không tồn tại hoặc không kinh doanh! Vui lòng chọn một sản phẩm khác');
            return ;
        }
        if (!isUpdate) {
            dataAll.map(quantity => {
                if (quantity.product_id === cart.id) {
                    quantity.quantity = cart.product_stock;
                }
            })
        }

        if (point > pointMe){
            dataAll[keySelect].quantity = quantityOld
            $('#input_quantity_' + cartIdChange).val(quantityOld)
            $('#input_quantity_mobile_' + cartIdChange).val(quantityOld)
            showAlert('Không đủ điểm để đổi sản phẩm!')
            return
        }
        
        if(tokenUser){
            CartApiService.postCart(dataAll).then(res => {
                cartNotAuth(res);
            })
        }else {
            CartApiService.postCartNotAuth(dataAll).then(res => {
                cartNotAuth(res);
                localStorage.setItem("cart_not_auth", JSON.stringify(res.data.products));
            })
        }
    }

    const ChangeSelect = async () => {
        let checkboxes = document.querySelectorAll('input[type="checkbox"].selectedId:checked')
        let dataChecked = []
        checkboxes.forEach(input => {
            let index = input.getAttribute('index')
            dataChecked.push(dataAll[index])
        })

        if (!dataChecked.length) {
            dataChecked.push(
                {
                    selected: false,
                    real_price: 0,
                    origin_price: 0,
                    coupon_value: 0
                }
            )
        }
        await calculateTotal(dataChecked)
    }
    
    const ChangeSelectMobile = async () => {
        let checkboxes = document.querySelectorAll('input[type="checkbox"].selectedIdMobile:checked')
        let dataChecked = []
        checkboxes.forEach(input => {
            let index = input.getAttribute('index')
            dataChecked.push(dataAll[index])
        })

        if (!dataChecked.length) {
            dataChecked.push(
                {
                    selected: false,
                    real_price: 0,
                    origin_price: 0,
                    coupon_value: 0
                }
            )
        }
        await calculateTotal(dataChecked)
    }

    const handleChange = async (evt, cart) => {
        quantity.map(productQuantity => {
            if (productQuantity.id === cart.id) {
                productQuantity.quantity = evt.target.value ? parseInt(evt.target.value) : ''
            }
        })
        await setDataQuantity(quantity)
    }

    const handleDeleteCart =(cart) => {
        const cartAfterDelete = dataCart && dataCart.filter((result) => result.id !== cart.id)
        setDataCart(cartAfterDelete);

        var data = [];
        cartAfterDelete && cartAfterDelete.map(cart => 
            {data.push({
                quantity: cart.quantity, 
                product_id: cart.id,
                selected: true,
            })
            }
        )
        if(tokenUser){
            CartApiService.postCart(data).then(res => {
                cartNotAuth(res);
            })
        }else {
            CartApiService.postCartNotAuth(data).then(res => {
                cartNotAuth(res);
                localStorage.setItem("cart_not_auth", JSON.stringify(res.data.products));
            })
        }
        setDataQuantity(state.cartData.products)
    }

    // only type number
    useEffect(() => {
        $('input[name="inputForNumber"]').keypress
        (function (event) {
                let value = $('input[name="inputForNumber"]').val()
                onlyNumber(event, value)
            }
        );
    })
    // handle multi check and all check
    const handleCheckMulti = (e) => {
        if(e.target.checked){
            // console.log('b3');
        }else {
            TotalCurrPriceCart = TotalCurrPriceCart - parseInt(e.target.value)
            return TotalCurrPriceCart
        }
    }

    //coupon
    const chooseCoupon = async (product, keyword='') => {
        await setProductCoupon(product)
        await getCouponList(product, keyword)
        await setShowVoucher(true)
    }

    const handleKeyword = async (e) => {
        await chooseCoupon(productCoupon, e.target.value)
    }

    const applyCoupon = async (coupon) => {
        if (!productCoupon.is_available) {
            showAlert('Sản phẩm hiện không tồn tại hoặc không kinh doanh! Vui lòng chọn một sản phẩm khác');
            return;
        }
        dataAll = dataAll.map(item => {
            if (item.product_id == productCoupon.id) {
                item.coupon_id = coupon.id
            }
            return item
        })
        CartApiService.postCart(dataAll).then(res => {
            if (res.status === true) {
                dispatch(GetAllDataCart(res.data));
            }
        })
        setShowVoucher(false)
    }

    const removeCoupon = async () => {
        dataAll = dataAll.map(item => {
            if (item.product_id == productCoupon.id) {
                item.coupon_id = 0
            }
            return item
        })
        CartApiService.postCart(dataAll).then(res => {
            if (res.status === true) {
                dispatch(GetAllDataCart(res.data));
            }
        })
        setShowVoucher(false)
    }
    
    const getCouponList = (product, keyword = '') => {
        CouponApiService.getByProduct(product.id, {code: keyword.trim()}).then(res => {
            if (res.status && res.data && res.data.length) {
                let now = new Date()
                let dataUsed = res.data.filter(item => {
                    let startTime = new Date(item.start_time)
                    let endTime = new Date(item.end_time)
                    if (startTime <= now && endTime >= now && item.number_of_use > 0 && product.product_real_price > item.value) {
                        return item;
                    }
                })
                let dataNotUsed = res.data.filter(item => {
                    let startTime = new Date(item.start_time)
                    let endTime = new Date(item.end_time)
                    if (now < startTime || now > endTime) {
                        return item;
                    }
                })
                setCouponList(dataUsed)
                setCouponListNotUse(dataNotUsed)
            } else {
                setCouponList([])
                setCouponListNotUse([])
            }
        })
    }
 
    return (
        <div>
        {totalProductsInCart !== 0 
            ?<div className="mainCart">
                    <div className="container">
                        <div className="row">
                            <div className="carts w-100">
                                <BreadCrumb from={'Trang chủ'} to={'Giỏ hàng'}/>
                                <div className="product mt-12">
                                    <div className="header-productTitle d-flex align-items-center justify-content-between w-100">
                                        <div className="title_header">
                                            <h2 className="mb-0">Giỏ hàng</h2>
                                        </div>
                                    </div>
                                    <div className="table-responsive table_cart">
                                        <table className="table tabl_cart">
                                        <thead>
                                            <tr>
                                                <th className="title_address">
                                                    <div className="d-flex">
                                                        <input type="checkbox" name="payment" defaultChecked={true} id="selectall" onChange={(event) => ChangeSelect()}/>
                                                        <label htmlFor="checkbox"></label>
                                                    </div>
                                                </th>
                                                <th>Danh sách sản phẩm</th>
                                                <th>Số lượng</th>
                                                <th>Tạm tính</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {dataCart &&  dataCart.map((cart, index) => 
                                            <tr key={index}>
                                                <th className="title_address">
                                                    <div className="d-flex">
                                                        <input value={cart.id} id={cart.id} type="checkbox" name="payment" defaultChecked={ dataAll[index] }
                                                        className="selectedId" index={index} onChange={(event) => ChangeSelect()}/>
                                                        <label htmlFor="checkbox"></label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <div className="d-flex">
                                                        <Link to={"/san-pham/" + cart.slug} onClick={() => {dispatch(websiteActions.setFooter(true))}}>
                                                            <img src={getImageMedia(cart.image)} alt={cart.name} />
                                                        </Link>
                                                        <div className="grid">
                                                            <Link className="hover_to_detailItem" to={`/san-pham/${cart.slug}`} onClick={() => {dispatch(websiteActions.setFooter(true))}}><h1 className="name_product">{cart.name}</h1></Link>
                                                            {cart.is_available ?
                                                              (cart.product_point ? <div className="price">
                                                                  <h2 style={{'color':'#F53C45'}}>{formatCurrency(cart.product_point)} điểm</h2>
                                                              </div> :
                                                              (cart.product_price === cart.product_real_price
                                                                ?
                                                                <div className="price">  
                                                                    <h2 style={{'color':'#F53C45'}}>{formatCurrency(cart.product_price)}đ</h2>
                                                                </div>
                                                                : 
                                                                <div className="d-flex price">
                                                                    <h2 style={{'color':'#F53C45'}}>{formatCurrency(cart.product_price)}đ</h2>
                                                                    <h4 style={{'color':'#F53C45'}}>{formatCurrency(cart.product_real_price)}đ</h4>

                                                                </div>
                                                              )) :             <div className="price">
                                                                  <h2 style={{'color':'#F53C45'}}>Sản phẩm không thể mua</h2>
                                                              </div> }
                                                            {cart.product_point == 0 && cart.is_available ?
                                                            <div className="voucher">
                                                                { cart.coupon_code ?
                                                                <div className="d-flex">
                                                                    <span>Giảm {formatCurrency(cart.coupon_value)}đ</span><p>mã giảm giá {cart.coupon_code}</p>
                                                                </div> : null }
                                                                {cart.coupon_code ?
                                                                <button type="button" className="button_change_vourcher" onClick={() => chooseCoupon(cart)}>Thay đổi</button>
                                                                  :  <button type="button" className="button_change_vourcher" onClick={() => chooseCoupon(cart)}>Chọn mã giảm giá</button> }
                                                            </div> : null }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="count">
                                                        <button type="button"  onClick={()=> DecreaseQuantity(cart)} className="btn-minus"><img src={icon_minus} alt="" /></button>
                                                        <input type="text" id={'input_quantity_' + cart.id} className="form-control" name="inputForNumber" value={ quantity.length && quantity[index] ? quantity[index]['quantity'] : 0}
                                                         onChange={(event) => handleChange(event, cart)} onBlur={(evt) => updateQuantity(evt, cart)}/>
                                                        <button type="button" onClick={()=> IncreaseQuantity(cart)} className="btn-plus"><img src={icon_plus} alt="" /></button>
                                                    </div>
                                                </td>
                                                <td>
                                                    {cart.product_point == 0 ?
                                                    <span>{formatCurrency(cart.origin_price)}đ</span>
                                                      : <span>{formatCurrency(cart.point)} điểm</span> }
                                                </td>
                                                <td onClick={() => handleShow(cart)}>
                                                    <Link to="#">Xóa</Link>
                                                </td>
                                            </tr>
                                        )}
                                            
                                            <tr className="total_price">
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <div className="grid">
                                                        <h4>Tạm tính</h4>
                                                        <h4>Giảm giá sản phẩm</h4>
                                                        <h2>Tổng thanh toán</h2>
                                                        <h2></h2>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="grid">
                                                        <h4>{ total.provisional ? formatCurrency(total.provisional) : 0}đ</h4>
                                                        <h4>{ total.discount ? formatCurrency(total.discount) : 0}đ</h4>
                                                        <h2 className="price_total">{ total.total_payment ? formatCurrency(total.total_payment) : 0}đ </h2>
                                                        <h2 className="price_total">{ totalPoint > 0 ? totalPoint + ' điểm' : '' } </h2>
                                                    </div>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    <div className="cart-items">
                                        <div className="title_address check-all">
                                            <div className="d-flex">
                                                <input type="checkbox" name="payment" defaultChecked={true} id="selectall-mobile" onChange={(event) => ChangeSelectMobile()}/>
                                                <label htmlFor="checkbox">Chọn tất cả</label>
                                            </div>
                                        </div>
                                        {dataCart && dataCart.map((cart, index) =>
                                            <div className="cart-item" key={index}>
                                                <div className="cart-zone-info">
                                                    <div className="title_address">
                                                        <div className="d-flex">
                                                            <input value={cart.id} type="checkbox" name="payment" defaultChecked={ dataAll[index] }
                                                                   className="selectedIdMobile" index={index} onChange={(event) => ChangeSelectMobile()}/>
                                                            <label htmlFor="checkbox"></label>
                                                        </div>
                                                    </div>
                                                    <div className="image_product_zone">
                                                        <Link to={"/san-pham/" + cart.slug} onClick={() => {dispatch(websiteActions.setFooter(true))}}>
                                                            <img src={getImageMedia(cart.image)} alt={cart.name} />
                                                        </Link>
                                                    </div>
                                                    <div className="cart_info_zone">
                                                        <Link className="hover_to_detailItem" to={`/san-pham/${cart.slug}`} onClick={() => {dispatch(websiteActions.setFooter(true))}}><p className="name_product">{cart.name}</p></Link>
                                                        {cart.is_available ?
                                                          (cart.product_point ?  <div className="price"><p>{formatCurrency(cart.product_point)} điểm</p></div> :
                                                          (cart.product_price === cart.product_real_price

                                                          ?
                                                          <div className="price">
                                                              <p>{formatCurrency(cart.product_price)}đ</p>
                                                          </div>
                                                          :
                                                          <div className="d-flex price">
                                                              <p>{formatCurrency(cart.product_price)}đ</p>
                                                              <span>{formatCurrency(cart.product_real_price)}đ</span>
                                                          </div>
                                                          )):  <div className="price">
                                                              <p>Sản phẩm không thể mua</p>
                                                          </div>}
                                                        {cart.product_point == 0 && cart.is_available ?
                                                        <div className="voucher">
                                                            {cart.coupon_code ?
                                                              <p><span>Giảm {formatCurrency(cart.coupon_value)}đ</span>mã giảm giá {cart.coupon_code}</p> : null }
                                                            {cart.coupon_code ?
                                                              <button type="button" className="button_change_vourcher" onClick={() => chooseCoupon(cart)}>Thay đổi</button>
                                                              :  <button type="button" className="button_change_vourcher" onClick={() => chooseCoupon(cart)}>Chọn mã giảm giá</button> }
                                                        </div> : null }
                                                        <div className="count">
                                                            <button type="button"  onClick={()=> DecreaseQuantity(cart)} className="btn-minus"><img src={icon_minus} alt="" /></button>
                                                            <input type="text" id={'input_quantity_mobile_' + cart.id} className="form-control" name="inputForNumber" value={ quantity.length && quantity[index] ? quantity[index]['quantity'] : 0}
                                                                   onChange={(event) => handleChange(event, cart)} onBlur={(evt) => updateQuantity(evt, cart)}/>
                                                            <button type="button" onClick={()=> IncreaseQuantity(cart)} className="btn-plus"><img src={icon_plus} alt="" /></button>
                                                        </div>
                                                        {cart.product_point == 0 ?
                                                        <span className="total-price">{formatCurrency(cart.origin_price)}đ</span>
                                                          : <span className="total-price">{formatCurrency(cart.point)} điểm</span> }
                                                        <span aria-hidden="true" className="delete_cart" onClick={() => {handleShow(cart)}}>×</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                      <div className="price-final">
                          <div className="total_price">
                              <div>
                                  <div className="grid">
                                      <span>Tạm tính</span>
                                      <span>Giảm giá sản phẩm</span>
                                      <p>Tổng thanh toán</p>
                                      { totalPoint ?
                                      <p>Tổng điểm</p>
                                        :null }
                                  </div>
                              </div>
                              <div className="price_data">
                                  <div className="grid">
                                      <span>{ total.provisional ? formatCurrency(total.provisional) : 0}đ</span>
                                      <span>{ total.discount ? formatCurrency(total.discount) : 0}đ</span>
                                      <p className="price_total">{ total.total_payment ? formatCurrency(total.total_payment) : 0}đ</p>
                                      { totalPoint ? <p className="price_total">{ totalPoint > 0 ? totalPoint + ' điểm' : '' } </p> : null}
                                  </div>
                              </div>
                          </div>
                      </div>
                    <div className="button_action_buy">
                        <div className="container">
                            <div className="row">
                                <div className="d-flex w-100 justify-content-between">
                                    <button type='button' className="button_buy_continue">
                                        <img src={imgCartBuy} alt=""/>
                                        <Link style={{'textDecoration':'none'}} to="/" onClick={() => {dispatch(websiteActions.setFooter(true))}}><span>Tiếp tục mua hàng</span></Link>
                                    </button>
                                    <button type="button" onClick={handlePurcharse}  className="button_buy">Mua hàng</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                  <Modal show={showVoucher} onHide={() => setShowVoucher(false)} className="modal-login modal-voucher" >
                      <Modal.Header closeButton  className="grid">
                          <Modal.Title>Chọn mã giảm giá</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="mt-3">
                          <Form>
                              <div className="form-group d-flex">
                                  <div className="grid">
                                      <input type="text" className="form-control" placeholder="Nhập mã giảm giá" onChange={handleKeyword}/>
                                      {/*<span className="validate_msg">Mã giảm giá không tồn tại</span>*/}
                                      {(couponList.length + couponListNotUse.length) == 0 ?
                                        <p className="not_voucher">Bạn chưa có mã giảm giá. Hãy quay lại sau nhé</p>
                                        : null}
                                  </div>
                              </div>
                              <ul className="list_voucher">
                                  { couponList.length ? couponList.map(item => {
                                      return <li key={item.id}>
                                          <div className="col-9 pl-0 pr-0">
                                              <h5 className="name_voucher">{item.code}</h5>
                                              <span className="price_voucher">Giá trị: { formatCurrency(item.value) } VND</span>
                                              <div className="grid">
                                                  <p>HSD: { convertDateTime(item.start_time, 'DD/MM/YYYY HH:mm') } - { convertDateTime(item.end_time, 'DD/MM/YYYY HH:mm') }</p>
                                                  <p>Số lượng : { item.number_of_use }</p>
                                              </div>
                                          </div>
                                          {productCoupon && productCoupon.coupon_code === item.code ?
                                            <div className="col-3 pl-0 pr-0">
                                                <button type="button" className="btn bt-apply">Đã áp dụng
                                                </button>
                                            </div> :
                                            <div className="col-3 pl-0 pr-0">
                                                <button type="button" className="btn bt-apply" onClick={() => applyCoupon(item)}>Áp dụng
                                                </button>
                                            </div>
                                          }
                                      </li>
                                  }) : null}

                              </ul>
                              { couponListNotUse.length ?
                                <ul className="list_voucher voucher_more">
                                    <h1>Mã giảm giá khác</h1>
                                    { couponListNotUse.length ? couponListNotUse.map(item => {
                                        return (
                                          <li key={item.id}>
                                              <div className="col-9 pl-0 pr-0">
                                                  <h5 className="name_voucher">{item.code}</h5>
                                                  <span className="price_voucher">Giá trị: { formatCurrency(item.value) + ' VND' }</span>
                                                  <div className="grid">
                                                      <p>HSD: { convertDateTime(item.start_time, 'DD/MM/YYYY HH:mm') } - { convertDateTime(item.end_time, 'DD/MM/YYYY HH:mm') }</p>
                                                      <p>Số lượng : { item.number_of_use }</p>
                                                      {new Date(item.start_time) > new Date() ?
                                                        <strong>*Mã giảm giá chưa bắt đầu</strong>
                                                        : <strong>*Mã giảm giá đã kết thúc</strong> }
                                                  </div>
                                              </div>
                                          </li>
                                        )
                                    }) : null }
                                </ul> : null }
                          </Form>
                      </Modal.Body>
                      {productCoupon && productCoupon.coupon_code ?
                        <Modal.Footer>
                            <button type="button" className="btn-remove-voucher" onClick={() => removeCoupon()}>Bỏ áp dụng mã giảm giá</button>
                        </Modal.Footer>
                        : null }
                  </Modal>
              </div>
                <Modal className="modal_deleteAdressUser" show={show} onHide={handleClose}>
                        <Modal.Header closeButton> 
                            
                        </Modal.Header>
                        <Modal.Body>
                            <p>Bạn có chắc chắn muốn xóa sản phẩm này khỏi giỏ hàng ?</p>               
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Không
                            </Button>
                            <Button variant="primary" onClick={() => {
                            handleDeleteCart(cartOneItemId)
                            handleClose()
                            }}>
                                Xoá sản phẩm
                            </Button>
                        </Modal.Footer>
                    </Modal>
              <Modal className="modal_deleteAdressUser" show={showStatus} onHide={handleCloseStatus}>
                  <Modal.Header closeButton>

                  </Modal.Header>
                  <Modal.Body>
                      <p>{message}</p>
                  </Modal.Body>
                  <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseStatus}>
                          Đóng
                      </Button>
                  </Modal.Footer>
              </Modal>
                </div>
        : 
            <CartEmpty/>
        }

        </div>
    )
}

export default Cart;
