import React from 'react';
import {Link} from 'react-router-dom';
import {finalPrice, formatCurrency, getImageMedia} from "../../../helpers/Function";

function ProductGroup({product}) {
    return (
      <div className="d-flex">
          { product.medias !== null ? <Link to={"/san-pham/" + product.slug}><img src={getImageMedia(product.medias)} alt={product.name}/></Link> : null}
          <div className="grid">
            <Link to={"/san-pham/" + product.slug}>
              <span>{product.name}</span>
            </Link>
            { product.type_id === 3 ?
              <div className="d-flex align-items-baseline">
                <h4>Liên hệ</h4>
              </div>
              :
            <div className="d-flex align-items-baseline">
                {(product.discount > 0 && product.discount < product.price) || (product.deal && product.deal.id) ? <h4>{formatCurrency(finalPrice(product))}đ</h4> : <h4>{formatCurrency(product.discount)}đ</h4>}
                {(product.discount > 0 && product.discount < product.price) || (product.deal && product.deal.id) ? <h5>{formatCurrency(product.price)}đ</h5> : ''}
            </div>}
          </div>
      </div>
    )
}

export default ProductGroup
