import React, {useEffect, useState} from 'react'
import SlideDetail from '../slide_detail/slide_detail';
import BreadCrumb from "../breadcrumb/Breadcrumb";
import TitleProduct from './component/title_product';
import {useHistory, useParams} from 'react-router-dom';
import TagInfomation from './component/tag_infomation';
import CommitDetail from './component/commit';
import icon_evalute from '../../../assets/img/validation 1.svg'
import ProductSameCategory from '../product_same_category/ProductSameCategory'
import icon_minus from '../../../assets/img/minus.png';
import icon_buys from '../../../assets/img/icon_buy.png';
import icon_plus from '../../../assets/img/plus icon.png';
import BannerFooter from "../banner-footer/BannerFooter";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Comment_ratings from '../rating/rating';
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from 'axios';
import ENDPOINT, {CartApiService, FaqService, ProductSeenService, ReviewApiService} from '../../../ultils/EndPoint';
import parse from 'html-react-parser';
import Login from '../../../pages/auth/login';
import { useDispatch, useSelector } from 'react-redux';
import {GetAllDataCart} from '../../../actions/Cart';
import {Pagination} from "antd";
import {getSumObject, objectToQueryParams, round} from "../../../helpers/Function";
import ReactStars from "react-rating-stars-component";
import Comment from "../comment/Comment";
import {websiteActions} from "../../../actions";
import NotFound from "../not_found/NotFound";
import {Button} from "react-bootstrap";

function DetailProduct() {
    // set slug for product detail
    const TYPE_PRODUCT_ORDER = 3;
    const {slug} = useParams()
    const history = useHistory()
    const [showLogin, setShowLogin] = useState(false);
    const dispatch = useDispatch()
    const state = useSelector(state => state.todoProduct)
    const stateCheckNumber = useSelector(state => state.todoProduct)


    const [dataInCart, setDataInCart] = useState([])
 
    useEffect(() => {
        if(stateCheckNumber.cartData){
            setDataInCart(state.cartData.products)
        }
    }, [state.cartData.products])


    let totalProductsInCart = 0
    dataInCart && dataInCart.map(number =>
        totalProductsInCart += number.quantity
    )

    var data = [];
    state.cartData.products && state.cartData.products.map(cart =>
      {data.push({
          quantity: cart.quantity, 
          product_id: cart.id,
          coupon_id: cart.coupon_id,
          selected: true,
      })
      }
    )

    // cartdata from api
    const [dataCartNew, setDataCartNew] = useState([])
    useEffect(() => {
        if(state.cartData.products){
            setDataCartNew(state.cartData.products)
        }
    }, [state.cartData.products])


    // acount number 
    const [inputAccount, setInputAccount] = useState(1);

    // set token check 
    const tokenUser = localStorage.getItem('User-token')

    // set const data of product
    const [dataOfProduct, setDataOfProduct] = useState([]);
    const [sameCategoryProducts, setSameCategoryProducts] = useState([]);
    const [productsSeen, setProductsSeen] = useState([]);
    const [notFound, setNotFound] = useState(false);
    // set show for modal
    const [show, setShow] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [message, setMessage] = useState(false);
    const handleClose = () => {
        setShow(false)
    };
    const handleCloseStatus = () => {
        setShowStatus(false)
    };
    const [show_question, setShowQuestion] = useState(false);
    const handleCloseQuestion = () => {
        setShowQuestion(false)
    };
    // set key for tab
    const [key, setKey] = useState('infomation');
    const [listReview, setListReview] = useState([])
    const [dataPaginate, setDataPaginate] = useState({});

    const [totalRate ,setTotalRate] = useState({})
    const [averageRate ,setAverageRate] = useState(0)
    const [starFilter ,setStarFilter] = useState(0)
    let perPageReview = 5

    function getAverageStar(obj){
        let sum = 0
        Object.keys(obj).map(function (item, key) {
            sum += (key+1) * obj[item]
        });
        return round(sum/(getSumObject(obj)), 0.5)
    }

    function getListReview(id, query = ''){
        setListReview([])
        if (!query){
            query = '?per_page=' + perPageReview
        }
        ReviewApiService.getListReview(id, query).then(async r => {
            if (r.status) {
                await setListReview(r.data.records)

                setDataPaginate({
                    "totalRecord": r.data.total_record,
                    "totalPage": r.data.total_page,
                    "currentPage": r.data.page,
                    "perPage": r.data.limit,
                })
                let extra_rate = r.data.extra_data
                await setTotalRate(extra_rate)
                setAverageRate(getAverageStar(extra_rate))
            }
        })
    }

    // set data when haved slug
    useEffect(async () => {
        if (slug) {
            axios.get(`${ENDPOINT.PRODUCTS}/${slug}`)
                .then(function (response) {
                    if (response.status === 200) {
                        setDataOfProduct(response.data.data);
                        getListReview(response.data.data.id)
                    }
                })
                .catch(err => {
                        if (err.response.status === 422) {
                            setNotFound(true)
                            // history.replace('/not-found')
                        }
                    }
                )
        }
    },[slug]);

    useEffect( async () => {
        if (dataOfProduct && dataOfProduct.id) {
            axios.get(`${ENDPOINT.PRODUCTS}/${dataOfProduct.id}/same-category`)
              .then(function (response) {
                  if (response.status === 200) {
                      setSameCategoryProducts(response.data.data)
                  }
              })
              .catch(err =>
                console.log(err)
              )
            if (tokenUser) {
                await ProductSeenService.storeProductSeen({product_id : dataOfProduct.id})
                await axios.get(`${ENDPOINT.PRODUCTS_SEEN}`, {
                    headers: {
                        Authorization: 'Bearer ' + tokenUser
                    }
                })
                  .then(function (response) {
                      if(response.status === 200) {
                          setProductsSeen(response.data.data);
                      }
                  })
                  .catch(err =>
                    console.log(err)
                  )
            }
            await FaqService.getList()
        }
    }, [dataOfProduct, dataOfProduct.id])

    //  show more or not
    useEffect(() => {
        $(function () {
            var content_tabs = $('.content_tabs').height();
            var content_tabs1 = $('.content_tabs1').height();
            var content_tabs2 = $('.content_tabs2').height();
            var content_tabs3 = $('.content_tabs3').height();
            var btn_hide = $('.btn_hide');
            var btn_show = $('.btn_showmore');
            var action_show = $('.show_more');
            $(btn_hide).hide();
            $(action_show).hide();
            if(content_tabs >= 700) {
                $(action_show).show();
                $('.btn_showmore').off('click').on('click',function () {
                    $(this).hide();
                    $(this).parent().addClass('active');
                    $(this).parents('.tab-pane').addClass('showmore');
                    $(btn_hide).show();
                    $(btn_hide).off('click').on('click',function () {
                        $(this).hide();
                        $(this).parent().removeClass('active');
                        $(btn_show).show();
                        $(this).parents('.tab-pane').removeClass('showmore');
                    })
                })
            }else if(content_tabs1 >= 700) {
                $(action_show).show();
                $('.btn_showmore').off('click').on('click',function () {
                    $(this).hide();
                    $(this).parent().addClass('active');
                    $(this).parents('.tab-pane').addClass('showmore');
                    $(btn_hide).show();
                    $(btn_hide).off('click').on('click',function () {
                        $(this).hide();
                        $(this).parent().removeClass('active');
                        $(btn_show).show();
                        $(this).parents('.tab-pane').removeClass('showmore');
                    })
                })
            }else if(content_tabs2 >= 700) {
                $(action_show).show();
                $('.btn_showmore').off('click').on('click',function () {
                    $(this).hide();
                    $(this).parent().addClass('active');
                    $(this).parents('.tab-pane').addClass('showmore');
                    $(btn_hide).show();
                    $(btn_hide).off('click').on('click',function () {
                        $(this).hide();
                        $(this).parent().removeClass('active');
                        $(btn_show).show();
                        $(this).parents('.tab-pane').removeClass('showmore');
                    })
                })
            }else if(content_tabs3 >= 700) {
                $(action_show).show();
                $('.btn_showmore').off('click').on('click',function () {
                    $(this).hide();
                    $(this).parent().addClass('active');
                    $(this).parents('.tab-pane').addClass('showmore');
                    $(btn_hide).show();
                    $(btn_hide).off('click').on('click',function () {
                        $(this).hide();
                        $(this).parent().removeClass('active');
                        $(btn_show).show();
                        $(this).parents('.tab-pane').removeClass('showmore');
                    })
                })
            }
        })
    })

    useEffect(() => {
        if(dataOfProduct.intro_short ){

            if(dataOfProduct.intro_short.length >= 212){

                $('#hide_more').hide();
                $("#show_more").off('click').on('click',function () {
                    $(this).hide();
                    $(this).parents('.sub_content_detail').addClass('active');
                    $('#hide_more').show();
                    $('#hide_more').off('click').on('click',function () {
                        $(this).hide();
                        $('#show_more').show();
                        $(this).parents('.sub_content_detail').removeClass('active');
                    })
                })
            }else {
                $('#hide_more').hide();
                $("#show_more").hide();
            }
        }
    })

    // acount before add to cart    
    const showAlert = (message) => {
        setMessage(message);
        setShowStatus(true);
    }

    function incButton(){
        if(dataOfProduct.product_stock === 0) {
            showAlert('Sản phẩm đã hết hàng!');
            return;
        } else if(dataOfProduct.status === 0) {
            showAlert('Sản phẩm đang ngừng bán!');
            return;
        }
        setInputAccount(inputAccount +1)
    }

    function decButton(){
        if(dataOfProduct.product_stock === 0) {
            showAlert('Sản phẩm đã hết hàng!');
            return;
        } else if(dataOfProduct.status === 0) {
            showAlert('Sản phẩm đang ngừng bán!');
            return;
        }
        setInputAccount(inputAccount -1)
    }

    if(inputAccount <= 1) {
        decButton = () => {
            setInputAccount(1)
        } 
    }


    if (dataOfProduct.product_stock !== 0) {
        if(inputAccount > dataOfProduct.product_stock){
            showAlert('Đã đạt đến số lượng mua tối đa cho phép của sản phẩm này.')
            setInputAccount(dataOfProduct.product_stock)
        }
    }

    const handleCountCartItem = (e) => {
        setInputAccount(parseInt(e.target.value) || inputAccount);
    }

    function checkQuantityStock(quantity, product) {
        if (product.product_stock < quantity) {
            showAlert('Bạn đã đặt mua tối đa cho phép của sản phẩm này!');
            return false
        }
        return true
    }

    function autoAddAndUpdateCart(res, buy, productId){
        if(res.status === true){
            dispatch(GetAllDataCart(res.data));
            if(!tokenUser){
                localStorage.setItem("cart_not_auth", JSON.stringify(res.data.products));
            }
            if (buy) {
                let product = res.data.products.find(item => item.id === productId.id)
                history.push('/thanh-toan?payment-products=[' + product.id + ']')
                return;
            }
            setShow(true)
        }
    } 

    // handle before and after add to cart
    const handleAddToCart = (productId, buy = false) => {
        if(dataOfProduct.product_stock == 0) {
            showAlert('Sản phẩm đã hết hàng!');
            return;
        } else if(dataOfProduct.status == 0) {
            showAlert('Sản phẩm đang ngừng bán!');
            return;
        }
        else {
            var result = data.find(obj => {
                return obj.product_id === productId.id
            })
            if(result){
                let isUpdate = checkQuantityStock(result.quantity + inputAccount, dataOfProduct)
                    data && data.map(check => {
                        if(check.product_id === productId.id){
                            check.quantity += inputAccount
                        }
                    })
                if(tokenUser){
                    if (isUpdate) {
                        CartApiService.postCart(data).then(res => {
                            autoAddAndUpdateCart(res, buy, productId)
                        })
                    }
                }else {
                    if (isUpdate) {
                        CartApiService.postCartNotAuth(data).then(res => {
                            autoAddAndUpdateCart(res, buy, productId)
                        })
                    }
                }
            }else {
                if(tokenUser){
                    CartApiService.postCart([{
                        product_id : productId.id,
                        quantity : inputAccount,
                        selected : true,
                    }].concat(data)).then(res => {
                        if(res.status === true){
                            dispatch(GetAllDataCart(res.data));
                            if (buy) {
                                let product = res.data.products.find(item => item.id === productId.id)
                                history.push('/thanh-toan?payment-products=[' + product.id + ']')
                                return;
                            }
                            setShow(true)
                        }
                    })
                }else {
                    CartApiService.postCartNotAuth([{
                        product_id : productId.id,
                        quantity : inputAccount,
                        selected : true,
                    }].concat(data)).then(res => {
                        if(res.status === true){
                            dispatch(GetAllDataCart(res.data));
                            localStorage.setItem("cart_not_auth", JSON.stringify(res.data.products));
                            if (buy) {
                                let product = res.data.products.find(item => item.id === productId.id)
                                history.push('/thanh-toan?payment-products=[' + product.id + ']')
                                return;
                            }
                            setShow(true)
                        }
                    })
                }
            }
        }
    }

    const handleGoToCart = async () => {
        await dispatch(websiteActions.setFooter(false))
        await history.replace('/cart')
    }

    const buyNow = async () => {
        // if(!tokenUser){
        //     setShowLogin(true)
        // }else {
        //     await handleAddToCart(dataOfProduct, true);
        // }

        await handleAddToCart(dataOfProduct, true);
    }

    function setCurrentPage(page){
        let data = {}
        data.page = page
        data.per_page = perPageReview
        if (starFilter !== 0){
            data.star = starFilter
        }
        let query = objectToQueryParams(data)
        getListReview(dataOfProduct.id, query)
    }

    function changeStarReview(star){
        let data = {}
        data.page = 1
        if (!star){
            setStarFilter(0)
        }
        if (star){
            data.star = star
            setStarFilter(star)
        }
        let query = objectToQueryParams(data)
        getListReview(dataOfProduct.id, query)
    }

    // only type number
    useEffect(() => {
        $('input[name="inputForNumber"]').keypress
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else 
                {
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

    if (notFound) {
        return (<NotFound />)
    }
    return (
        <div>
            <Modal show={show} onHide={handleClose} className="modal_deleteAdressUser modal_AddSuccess_Cart">
              <Modal.Header closeButton className="grid">
                  <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body className="mt-3">
                  <Form>
                      <p className="mb-3">Thêm vào giỏ hàng thành công</p>       
                      <div variant="primary" onClick={() => {
                          handleGoToCart()
                          handleClose()
                      }} className="form-group mb-0">
                          <button type="button" className="btn btn-submit goto_cart">Xem giỏ hàng</button>
                      </div>
                  </Form>
              </Modal.Body>
          </Modal>
            <Modal className="modal_deleteAdressUser" show={showStatus} onHide={handleCloseStatus}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseStatus}>
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
            <Login show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
            <div className="container">
                <div className="row">
                    <BreadCrumb
                        from={"Trang chủ"} 
                        to={dataOfProduct && dataOfProduct.category ? dataOfProduct.category.name : ''} 
                        toNameSlug={'danh-muc'}
                        toSlug={dataOfProduct && dataOfProduct.category ? dataOfProduct.category.slug : ''}
                        detail={dataOfProduct.name}
                        checkend={true}
                    />

                    <div className="content_detail_product">
                        <div className="left_detail">
                            <div className="d-flex">
                                <div className="slide_details myContainer">
                                    <SlideDetail slide={dataOfProduct.medias}/>
                                </div>
                                <div className="count_detail_product">
                                    <div className="infomation_detail_product">
                                        <TitleProduct name={dataOfProduct.name || ''} price={dataOfProduct.price || ''} discount={dataOfProduct.discount || ''} type={dataOfProduct.type_id || ''} product={dataOfProduct || ''}/>
                                        {(dataOfProduct.type_id != TYPE_PRODUCT_ORDER) ?
                                        <div>
                                            <div className="row m-0"> 
                                                <div className="amount_product">
                                                <div className="d-flex">
                                                    <button type="button" onClick={()=> decButton()} className="btn-minus">
                                                        <img src={icon_minus} alt=""/>
                                                    </button>
                                                    <input 
                                                        type="text"
                                                        name="inputForNumber"
                                                        className="form-control" 
                                                        onChange={handleCountCartItem} 
                                                        value={inputAccount} />
                                                    <button type="button" onClick={()=> incButton()} className="btn-plus ">
                                                        <img src={icon_plus} alt=""/>
                                                    </button>
                                                </div>
                                                </div>
                                                { dataOfProduct.status == 0 ?  <span className="amount_product_brands" style={{'color': "red", 'fontWeight': 'bold'}}>Ngừng bán</span> :(
                                                  dataOfProduct.product_stock > 0 ? <span className="amount_product_brands">còn {dataOfProduct.product_stock} sản phẩm</span>
                                                  : <span className="amount_product_brands" style={{'color': "red", 'fontWeight': 'bold'}}>Hết hàng</span>) }
                                            </div>
                                            <div className="btn_buy">
                                                <button onClick={() => handleAddToCart(dataOfProduct)} type="button" className="btn btn-add-cart">
                                                    <img src={icon_buys} alt=""/>
                                                    <span>Thêm vào giỏ hàng</span>
                                                </button>
                                                <button onClick={() => buyNow()}  type="button" className="btn btn-buys">Mua ngay</button>
                                            </div> 
                                        </div> 
                                        : 
                                            <div className="d-flex">
                                                <button type="button" className="btn btn_request" onClick={() => dispatch(websiteActions.setOpenChat(true))}>Yêu cầu tư vấn</button>
                                                <button type="button" className="btn btn_chat" onClick={() => dispatch(websiteActions.setOpenChat(true))}>Trò chuyện cùng dược sĩ</button>
                                            </div>
                                        }
                                        
                                        <div className="sub_content_detail">
                                            {dataOfProduct.intro_short !== '' 
                                            ? 
                                            <div>
                                                <p>{dataOfProduct.intro_short}</p> 

                                                <p style={{'color':'blue', 'cursor':'pointer'}} id="show_more">Xem thêm</p>
                                                <p style={{'color':'blue', 'cursor':'pointer'}} id="hide_more">Ẩn bớt</p>
                                            </div>
                                            : ''} 
                                        </div>
                                        <TagInfomation 
                                            category={dataOfProduct.category ? dataOfProduct.category.name : ''} 
                                            capacity={dataOfProduct.capacity}
                                            madeIn={dataOfProduct.made_in}
                                            brand={dataOfProduct.brand ? dataOfProduct.brand.name : ''}
                                            cateSlug={dataOfProduct.category ? dataOfProduct.category.slug : ''}
                                            slugBrand={dataOfProduct.brand ? dataOfProduct.brand.slug : ''}
                                        />
                                    </div>
                                </div>
                            </div>

                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="tab_infomation"
                            >
                                <Tab eventKey="infomation" title="Thông tin chung">
                                    <div className="content_tabs">
                                        {dataOfProduct.description ? parse(dataOfProduct.description) : ''}
                                    </div>
                                    <div className="show_more">
                                        <button type="button" className="btn btn_showmore">Xem thêm</button>
                                        <button type="button" className="btn btn_hide">Thu gọn</button>
                                    </div>
                                </Tab> 
                                <Tab eventKey="ingredient" title="Thành phần">
                                    <div className="content_tabs1">
                                        {dataOfProduct.components ? parse(dataOfProduct.components) : ''}
                                    </div>
                                    <div className="show_more">
                                        <button type="button" className="btn btn_showmore">Xem thêm</button>
                                        <button type="button" className="btn btn_hide">Thu gọn</button>
                                    </div>
                                </Tab>
                                <Tab eventKey="uses" title="Công dụng">
                                    <div className="content_tabs2">
                                        {dataOfProduct.benefits ? parse(dataOfProduct.benefits) : ''}
                                    </div>
                                    <div className="show_more">
                                        <button type="button" className="btn btn_showmore">Xem thêm</button>
                                        <button type="button" className="btn btn_hide">Thu gọn</button>
                                    </div>
                                </Tab>
                                <Tab eventKey="how_uses" title="Cách dùng">
                                    <div className="content_tabs3">
                                        {dataOfProduct.usages ? parse(dataOfProduct.usages) : ''}
                                    </div>
                                    <div className="show_more">
                                        <button type="button" className="btn btn_showmore">Xem thêm</button>
                                        <button type="button" className="btn btn_hide">Thu gọn</button>
                                    </div>
                                </Tab>
                            </Tabs>
                            {getSumObject(totalRate) ?
                              <div className="ratings" id="to_rating">
                                  <div className="title_ratings">
                                      <h1>Đánh giá sản phẩm</h1>
                                  </div>
                                  <div className="content_ratings">
                                      <div className="d-flex">
                                          <div className="total_ratings">
                                              <h3>{ averageRate.toString().length == 1 ? averageRate + '.0' : averageRate}</h3>
                                              <span>{getSumObject(totalRate)} Đánh giá</span>
                                              {averageRate ?
                                              <ReactStars
                                                count={5}
                                                size={20}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                activeColor="#ffd700"
                                                value={averageRate}
                                                isHalf={true}
                                                edit={false}
                                              /> : null }
                                          </div>
                                          <ul className="nav mb-0 mt-0 tab_infomation" id="myTab" role="tablist">
                                              <li className="nav-item" role="presentation">
                                                  <a className="nav-link active" id="all-tab" data-toggle="tab"
                                                     onClick={() => changeStarReview("")} role="tab"
                                                     aria-controls="home" aria-selected="true">Tất
                                                      cả({getSumObject(totalRate)})</a>
                                              </li>
                                              <li className="nav-item" role="presentation">
                                                  <a className="nav-link" id="5star-tab" data-toggle="tab"
                                                     onClick={() => changeStarReview(5)} role="tab"
                                                     aria-controls="5star" aria-selected="false">5
                                                      sao({totalRate['star_5']})</a>
                                              </li>
                                              <li className="nav-item" role="presentation">
                                                  <a className="nav-link" id="4star-tab" data-toggle="tab"
                                                     onClick={() => changeStarReview(4)} role="tab"
                                                     aria-controls="4star" aria-selected="false">4
                                                      sao({totalRate['star_4']})</a>
                                              </li>
                                              <li className="nav-item" role="presentation">
                                                  <a className="nav-link" id="3star-tab" data-toggle="tab"
                                                     onClick={() => changeStarReview(3)} role="tab"
                                                     aria-controls="3star" aria-selected="false">3
                                                      sao({totalRate['star_3']})</a>
                                              </li>
                                              <li className="nav-item" role="presentation">
                                                  <a className="nav-link" id="2star-tab" data-toggle="tab"
                                                     onClick={() => changeStarReview(2)} role="tab"
                                                     aria-controls="2star" aria-selected="false">2
                                                      sao({totalRate['star_2']})</a>
                                              </li>
                                              <li className="nav-item" role="presentation">
                                                  <a className="nav-link" id="1star-tab" data-toggle="tab"
                                                     onClick={() => changeStarReview(1)} role="tab"
                                                     aria-controls="1star" aria-selected="false">1
                                                      sao({totalRate['star_1']})</a>
                                              </li>
                                          </ul>
                                      </div>
                                      <div className="tab-content  w-100">
                                          <div className="tab-pane active not_box p-0" id="all" role="tabpanel"
                                               aria-labelledby="all-tab">
                                              {
                                                  listReview && listReview.map((review, index) => {
                                                        return <Comment_ratings review={review} key={index}/>
                                                    }
                                                  )
                                              }
                                              {dataPaginate.totalRecord <= perPageReview ? '' :
                                                <Pagination
                                                  defaultCurrent={dataPaginate.currentPage}
                                                  onChange={(value) => {
                                                      document.getElementById('to_rating').scrollIntoView({behavior: 'smooth'});
                                                      setCurrentPage(value)
                                                  }}
                                                  total={dataPaginate.totalRecord}
                                                  current={dataPaginate.currentPage}
                                                  defaultPageSize={perPageReview}
                                                  showSizeChanger={false}
                                                />
                                              }
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              :
                              <div className="ratings faq">
                                  <div className="title_ratings">
                                      <h1>Đánh giá sản phẩm</h1>
                                  </div>
                                  <div className="content_ratings">
                                      <form action="">
                                          <div className="form-group">
                                              <div className="row">
                                                  <div className="col-12 col-md-2">
                                                      <img style={{'width': '148px', 'height': '109px'}} alt=""
                                                           src={icon_evalute}></img>
                                                  </div>
                                                  <div className="col-12 col-md-10" style={{'paddingLeft': '24px'}}>
                                                      <label htmlFor="">Chưa có đánh giá dành cho sản phẩm <span
                                                        style={{'fontWeight': 'bold'}}>{dataOfProduct.name}</span></label>
                                                      <label htmlFor="">Bạn hãy mua hàng và đánh giá ngay sản phẩm với
                                                          chúng tôi nhé!</label>
                                                  </div>
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                              </div>
                            }
                            <div>
                            <Comment product={dataOfProduct}/>
                            </div>
                            
                            <ProductSameCategory name={"Sản phẩm cùng danh mục"} dataSameCategory={sameCategoryProducts} />
                            {tokenUser ? <ProductSameCategory name={"Sản phẩm đã xem"} dataSameCategory={productsSeen} /> : ''}
                            {/* <ProductView dataProductSeen={productsSeen} name={"Sản phẩm đã xem"}*/}
                          
                        </div>
                        <div className="right_detail">
                            <CommitDetail />
                        </div>
                    </div>
                </div>
            </div>
            <BannerFooter/>
        {/* <Modal show={show_question} onHide={handleCloseQuestion} className="modal-login modal-chat">
            <Modal.Header closeButton className="grid">
                <Modal.Title>Đặt câu hỏi</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mt-3">
                <Form>
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Họ và tên *"/>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Số điện thoại"/>
                    </div>
                    <div className="form-group">
                        <textarea name="" id="" cols="30" rows="3" className="form-control" placeholder="Ghi chú"></textarea>
                    </div>
                    <div className="form-group mb-0">
                        <button type="button" className="btn btn-submit">Gửi yêu cầu</button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal> */}
        </div>
    )
}

export default DetailProduct
