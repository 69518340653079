import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector} from 'react-redux';
import icon_login_gg from "../../assets/img/icons8-google 1.png";
import icon_login_fb from "../../assets/img/icons8-facebook 1.png";
import icon_register from "../../assets/img/Expand_right_double_light.png";
import { actions } from '../../actions/User';
import AccountPhone from './account_phone';
import ForgotPwd from './forgot_pwd';
import isEmpty from "validator/lib/isEmpty";
import '../../assets/scss/styles.scss'
import eyeLogin from '../../assets/img/icon-eye (1).svg'
import $ from 'jquery';
import logo_login from "../../assets/img/oops 1.png";
import * as LoginSocialNetWerk from '../../components/common/social-login/Social_login'
import { useHistory } from 'react-router-dom';
import { websiteActions } from '../../actions';
import { CartApiService } from '../../ultils/EndPoint';

function Login(props) {
    // set const
    const history = useHistory();    
    const dataMess = useSelector(state => state.notificationReducer.payload)
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showMissPassword, setShowMissPassword] = useState(false);
    const [showAccountPhone, setshowAccountPhone] = useState(false);
    const handleClose = () => {
        setShow(props.close)
        dispatch(websiteActions.setModal(false))
    };
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [validationMsg, setValidationMsg] = useState({});
    const [disable, setDisable]= useState(false);

    // regex for test
    const isPhoneNumber = new RegExp("^[0-9]*$")

    // set func
    const handleNameLogin = (e) => {
        setName(e.target.value)
    }

    const handlePasswordLogin = (e) => {
        setPassword(e.target.value)
    }

    // handle when user start keypress
    const handlePressName = () => {
        validationMsg.name = '';
        validationMsg.all = ''
        $('.form-name').removeClass('red_blur')
    }

    const handlePresspassword = () => {
        validationMsg.password = '';
        validationMsg.all = ''
        $('.form-password').removeClass('red_blur')
    }

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(isEmpty(name)) {
            msg.name = 'Vui lòng nhập số điện thoại'
            $('.form-name').addClass('red_blur')
        }else if (!isPhoneNumber.test(name) ||  name.length !== 10){
            msg.name = 'Số điện thoại không hợp lệ. Số điện thoại là 10 số, không chứa đầu +84'
            $('.form-name').addClass('red_blur')
        }else if(name.charAt(0) !== '0') {
            msg.name = 'Số điện thoại phải có định dạng 0xxxxxxxxx'
            $('.form-name').addClass('red_blur')
        }

        if(isEmpty(password)) {
            msg.password = 'Vui lòng nhập mật khẩu'
            $('.form-password').addClass('red_blur')
        }else if(password.length < 6) {
            msg.password = 'Mật khẩu phải nhiều hơn 6 kí tự'
            $('.form-password').addClass('red_blur')
        }
  
        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    if(dataMess === 422) {
        validationMsg.all = 'Tên đăng nhập hoặc mật khẩu không hợp lệ '
    }

    function postCartAuth(cartData) {
        CartApiService.postCart(cartData).then(res => {
            if(res.status === true){
                window.location.href = '/'
            }
        })
    }


    function mergeCart(response, newCartMerge) {
        const cartNotAuth = JSON.parse(localStorage.getItem("cart_not_auth"));

        var dataCartNotAuth = [];
        cartNotAuth && cartNotAuth.map(cart => 
            {dataCartNotAuth.push({
                quantity: cart.quantity, 
                product_id: cart.id,
                selected: true,
            })
            }
        )
        
        if(response.data.products){
            let cartMerge = response.data.products && response.data.products.concat(cartNotAuth || []).reduce((a, c) => {
                let filtered = a.filter(el => el.id === c.id);
                if(filtered.length > 0){
                    if((a[a.indexOf(filtered[0])].quantity += +c.quantity) >= a[a.indexOf(filtered[0])].product_stock){
                        a[a.indexOf(filtered[0])].quantity = a[a.indexOf(filtered[0])].product_stock;
                    }else{
                        a[a.indexOf(filtered[0])].quantity = a[a.indexOf(filtered[0])].quantity
                    }
                } else{
                    a.push(c);
                }
                return a;
            }, []);
            
            cartMerge && cartMerge.map(cart => {
                    newCartMerge.push({
                    quantity: cart.quantity, 
                    product_id: cart.id,
                    selected: true,
                })}
            )
            postCartAuth(newCartMerge)
           
        } else if(!response.data.products && cartNotAuth){
            postCartAuth(dataCartNotAuth)
        } else {
            window.location.reload()
        }
    }

    // params for call API
    const user = {
        phone : name,
        password : password
    }

    // when ok --> submit
    const onSubmitLogin = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 5000);

        dispatch(actions.signInRequest(user));
        CartApiService.postCartNotAuth([]);
       
        setTimeout(() => {
            if(localStorage.getItem('User-token')) {
                handleClose()
                let newCartMerge = [];
                CartApiService.getCart()
                .then(function (response) {
                    mergeCart(response, newCartMerge);
                    localStorage.removeItem('cart_not_auth');
                })
            }
        }, 1000);
    }

    // remove validate when close pop-up
    if(props.show) {   
    }else {
        validationMsg.name = '';
        validationMsg.password = '';
        validationMsg.all = '';
    }

    useEffect(() => {
        $('input[name="inputForNumber"]').keypress
        (function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else
                {
                    if (event.keyCode < 48 || event.keyCode > 57 )
                    {
                        event.preventDefault();
                    }
                }
            }
        );
    })
      
    return (
        <div>
            <ForgotPwd  show={showMissPassword} close={() => {setShowMissPassword(false)}} onHide={() => {setShowMissPassword(false)}}/>
            <AccountPhone show={showAccountPhone} close={() => {setshowAccountPhone(false)}} onHide={() => {setshowAccountPhone(false)}} />
            <Modal show={props.show} onHide={handleClose} className="modal-login">
                <Modal.Header closeButton className="grid">
                    {props.name 
                    ? 
                    <div>
                        <img src={logo_login} alt=""/>
                        <Modal.Title>{props.name}</Modal.Title> 
                    </div>
                    :  <Modal.Title>Đăng nhập tài khoản {process.env.REACT_APP_NAME}</Modal.Title>}
                </Modal.Header>
                <Modal.Body className="mt-3">
                    <Form>
                        <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.all}</p> 
                        <div className="form-group">
                            <input type="text" name="inputForNumber" onChange={handleNameLogin} onKeyDown={handlePressName} className="form-control form-name" placeholder="Số điện thoại"/>
                            <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.name}</p> 
                        </div>
                        <div className="form-group password">
                            <div className="input-group auth-pass-inputgroup">
                                <input type="password" onChange={handlePasswordLogin} onKeyDown={handlePresspassword} className="form-control form-password" placeholder="Mật khẩu"/>
                                <button className="btn btn-light" type="button" id="password-addon"><i className="mdi mdi-eye-outline"><img alt="" src={eyeLogin}></img></i></button>
                            </div>
                            <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.password}</p> 
                        </div>
                        <div className="form-group d-flex justify-content-end">
                            <a onClick={() => {setShowMissPassword(true) ; handleClose()}} className="forgot_pwd">Quên mật khẩu</a>
                        </div>
                        <div className="form-group">
                            <Button  onClick={onSubmitLogin} disabled={disable}  className="button_login">Đăng nhập</Button>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="form-group w-100">
                        <label htmlFor="">Đăng nhập bằng</label>
                        <div className="d-flex w-100">
                            <Button className="btn_login_other">
                             <img src={icon_login_gg} alt=""/>
                            </Button>
                            <LoginSocialNetWerk.GoogleLoginAuth/>
                            <Button className="btn_login_other">
                                <img src={icon_login_fb} alt=""/>
                            </Button>
                            <LoginSocialNetWerk.FaceBookLoginAuth/>
                            <Button className="btn_register"  onClick={() => {setshowAccountPhone(true) ; handleClose()}}>
                                Đăng ký tài khoản
                                <img src={icon_register} alt=""/>
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


export default Login;
