import React, {useEffect, useState} from 'react';
import Filter from '../components/common/filter/filter';
import BreadCrumb from '../components/common/breadcrumb/Breadcrumb';
import BannerFooter from "../components/common/banner-footer/BannerFooter";
import SidebarCategory from '../components/common/sidebar/sibar_category';
import FeatureGroup from '../components/common/feature-group';
import {useHistory, useParams} from "react-router-dom";
import {BannerApiService, ProductApiService, ProductSeenService} from "../ultils/EndPoint";
import BannerMainCategory from "../components/common/banner-category/BannerMainCategory";
import ListBannerCategory from "../components/common/banner-category/ListBannerCategory";
import NotFound from "../components/common/not_found/NotFound";
import ProductItem from "../components/common/product-item";
import { Pagination } from 'antd';
import {Helmet} from "react-helmet";
import {
    appendQueryParamsAndRedirect, deleteValueEmptyFromObject, getJsonQueryParams,
    getQuerystring
} from "../helpers/Function";
import {DEFAULT_LIMIT_SEARCH} from "../helpers/constant";
import ProductSameCategory from "../components/common/product_same_category/ProductSameCategory";

function CategoryProduct() {
    const {slug} = useParams();
    let allQuery = getQuerystring("")
    const tokenUser = localStorage.getItem('User-token')

    const [bannerMain, setBannerMain] = useState(null);
    const [bannerCategory, setBannerCategory] = useState(null);
    const [isNotFound, setNotFound] = useState(false);
    const [category, setCategory] = useState(false);
    const [categoryId, setCategoryId] = useState(false);
    const [products, setProduct] = useState([]);
    const [total, setTotal] = useState();
    const [customersPerPage] = useState(12);
    const [currentPage, setcurrentPage] = useState(1);
    const [selectSort, setSelectSort] = useState('product_sell desc');
    const [brands, setBrands] = useState([]);
    const [productsSeen, setProductsSeen] = useState([]);

    const history = useHistory()
    const handleSelectedSort = (e) => {
        setSelectSort(e.target.value)
        appendQueryParamsAndRedirect('order_by', e.target.value, history)
        setCurrentPage(1)
    }

    const setCurrentPage = (page) => {
        setcurrentPage(page)
        appendQueryParamsAndRedirect('page', page , history)
    }

    //banner-main
    useEffect(async () => {
        const TYPE_MAIN_CATEGORY_BANNER = 3;
        const TYPE_CATEGORY_BANNER = 4;
        BannerApiService.getBannerByType(TYPE_MAIN_CATEGORY_BANNER).then(res => {
            let banners = res.data;
            if (banners && banners.length > 0) {
                setBannerMain(banners[0])
            }
        })
        BannerApiService.getBannerByType(TYPE_CATEGORY_BANNER).then(res => {
            let banners = res.data;
            if ( banners && banners.length == 4) {
                setBannerCategory(banners)
            }
        })

        ProductApiService.getBrands().then(res => {
            if (res.status) {
                setBrands(res.data)
            }
        })

        let res = await ProductSeenService.get()
        if (res.status) {
            setProductsSeen(res.data)
        }
    }, [])

    //category
    useEffect(() => {
        if (slug) {
            ProductApiService.getCategoryBySlug(slug).then(res => {
                if (typeof res.error !== 'undefined') {
                    setNotFound(true)
                }
                if (res.data) {
                    if (!res.data.status) {
                        setNotFound(true)
                        return;
                    }
                    setCategory(res.data)
                    let categories = [res.data.id]
                    if (res.data.childs && res.data.childs.length) {
                        categories = categories.concat.apply(categories, res.data.childs.map(item => {
                            return item.id
                        }))
                        let childData =  res.data.childs.filter(item => {
                            return item.Child && item.Child.length
                        })
                        for (let i = 0; i < childData.length; i++) {
                            let item = childData[i];
                            categories = categories.concat.apply(categories, item.Child.map(child => {
                                return child.id
                            }));
                        }
                    }
                    if (res.data.Child && res.data.Child.length) {
                        categories = categories.concat.apply(categories, res.data.Child.map(item => {
                            return item.id
                        }))
                    }
                    setCategoryId(categories.join(','))
                }
            })
        }
    }, [slug])

    //product
    useEffect(() => {

        let dataParam = getJsonQueryParams()
        dataParam.per_page = DEFAULT_LIMIT_SEARCH

        if (categoryId){
            dataParam.category_id = '[' + categoryId + ']'
        }
        deleteValueEmptyFromObject(dataParam)

        if (categoryId) {
            ProductApiService.getListProduct(dataParam).then(res => {
                setProduct(res.data.records || [])
                setTotal(res.data.total_record || 0)
            })
        }
    }, [allQuery,categoryId, customersPerPage, currentPage, selectSort])

    if (isNotFound) {
        return (<NotFound />)
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{category.meta_title}</title>
                <meta name="description" content={category.meta_desc}/>
                <meta name="keywords" content={category.meta_key}/>
            </Helmet>
            <div className="container">
                <div className="row">
                    <BreadCrumb
                        from={"Trang chủ"}
                        to={'Danh mục sản phẩm'}
                        toNameSlug={undefined}
                        toSlug={undefined} 
                        detail={category.name}/>
                    <FeatureGroup />
                    <div className="main product_group d-flex">
                        <div className="main_filter">
                            <div className="banner_category grid mobile">
                                <BannerMainCategory banner={bannerMain} />
                                <ListBannerCategory banners={bannerCategory} />
                            </div>
                            <SidebarCategory category={category}/>
                            <Filter brands={brands}/>

                        </div>
                        <div className="main_product">
                            <div className="banner_category grid pc">
                                <BannerMainCategory banner={bannerMain} />
                                <ListBannerCategory banners={bannerCategory} />
                            </div>
                            <div className="product">
                                {products.length ?
                                  <div
                                    className="header-productTitle d-flex align-items-center justify-content-between">
                                      <div className="title_header mb-3">
                                          <h2 className="mb-0">{category.name}</h2>
                                          <p>Hiển thị {customersPerPage * (currentPage - 1) + 1} - {customersPerPage * currentPage < total ? customersPerPage * currentPage : total} trong số {total} Sản
                                              phẩm</p>
                                      </div>
                                      <select value={decodeURIComponent(getQuerystring("order_by"))} className="form-control custom_select" onChange={handleSelectedSort}>
                                          <option value="product_sell desc">Sản phẩm bán chạy</option>
                                          <option value="discount asc">Giá tăng dần</option>
                                          <option value="discount desc">Giá giảm dần</option>
                                          <option value="name asc">Xếp thứ tự A - Z</option>
                                          <option value="name desc">Xếp thứ tự Z - A</option>
                                      </select>
                                  </div> :                                   <div
                                    className="header-productTitle d-flex align-items-center justify-content-between">
                                      <div className="title_header">
                                          <h2 className="mb-0">{category.name}</h2>
                                          <p>Không có sản phẩm nào</p>
                                      </div>
                                  </div>
                                }
                                <div className="parents">
                                    <div className="grid grid-4 grid-gap-10">
                                        {products !== null ?
                                          products.map((product, index) => <ProductItem key={index} product={product} />) : ''}
                                    </div>
                                </div>
                                {total <= customersPerPage ? '' :
                                  <Pagination
                                    defaultCurrent={currentPage}
                                    onChange={(value) => {window.scrollTo({top: 800, left: 0, behavior: 'smooth' }); setCurrentPage(value) }}
                                    total={total}
                                    current={currentPage}
                                    defaultPageSize={customersPerPage}
                                    showSizeChanger= {false}
                                  />
                                }
                            </div>
                            { tokenUser ? <ProductSameCategory name={"Sản phẩm đã xem"} dataSameCategory={productsSeen} /> : '' }
                            {/*<ProductView name={"Sản phẩm đã xem"} />*/}
                        </div>
                    </div>
                </div>
            </div>
            <BannerFooter />
        </div>
    )
}
export default CategoryProduct;
