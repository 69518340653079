import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.citimed.viralsoft.vn/';
const VERSION = process.env.REACT_APP_API_VERSON1 || 'api/v1/';
const API_URL = BASE_URL + VERSION;
const PER_PAGE_NEWS_HOME_PAGE = 3

const ENDPOINT = {
    // auth 
    LOGIN:  BASE_URL + VERSION + 'client/login',
    LOGINAUTH:  BASE_URL + VERSION + 'client/me',
    REGISTERGETOTP: BASE_URL + VERSION +'client/get-otp',
    CONFIRMOTP: BASE_URL + VERSION + 'client/confirm-otp',
    REGISTER: BASE_URL + VERSION + 'client/register',
    RESETPASSWORD: BASE_URL + VERSION + 'client/reset-password',

    // change password
    CHANGE_PASSWORD_CLIENT: BASE_URL + VERSION + 'client/users/change-password',

    // update profile
    UPDATE_PROFILE_CLIENT: BASE_URL + VERSION + 'client/users/update',

    // adress user
    ADRESS_USER: BASE_URL + VERSION + 'client/users/address',
    ADRESS_USER_DEFAULT: BASE_URL + VERSION + 'client/users/address/default',

    // term of services
    TERM_SERVICES : BASE_URL + VERSION + 'client/static-page',

    // adress user list
    CITIES_LIST: BASE_URL + VERSION + 'cities',
    DISTRICTS_LIST: BASE_URL + VERSION + 'districts',
    WARDS_LIST: BASE_URL + VERSION + 'wards',

    // socail login 
    SOCAIL_LOGIN:  BASE_URL + VERSION + 'client/social/login',

    // BRANDS
    BRANDS : BASE_URL + VERSION + 'client/products/brands',

    // PRODUCT
    PRODUCTS : BASE_URL + VERSION + 'client/products',
    PRODUCTS_SEEN : BASE_URL + VERSION + 'client/users/product-seen/list',

    // CART
    CARTLIST: BASE_URL + VERSION + 'client/cart',

    // FAVORITE DELTE 
    FAVORITE_PRODUCT: BASE_URL + VERSION + 'client/users/product-favorite',

    // ORDER
    ORDERS: BASE_URL + VERSION + 'client/orders/',
    ORDERS_PAYMENT: BASE_URL + VERSION + 'client/orders/payment/',

}

export default ENDPOINT

export const ApiService = {

    setFormData() {
        axios.defaults.headers.common[
          "Content-Type"
          ] = "multipart/form-data";
    },

    setHeader() {
        if (process.browser) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem('User-token') ;
        }
    },

    get(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return axios.get(API_URL + url, {
            params: params
        })
          .then((res) => {
              return res.data
          })
          .catch((error) => {
              return error.response.data
          })
    },

    post(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return axios.post(API_URL + url, params)
          .then((res) => {
              return res.data
          })
          .catch((error) => {
              return error.response.data
          })
    },

    put(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return axios.put(API_URL + url, params)
          .then((res) => {
              return res.data
          })
          .catch((error) => {
              return error.response.data
          })
    },


    delete(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return axios.delete(API_URL + url, {params: params})
          .then((res) => {
              return res.data
          })
          .catch((error) => {
              return error.response.data
          })
    },

    async getRequest(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return await axios.get(API_URL + url, {params: params})
          .then((res) => {
              return res.data.data
          })
          .catch((error) => {
              return error.response.data
          })
    },

    async postRequest(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return await axios.post(API_URL + url, params)
          .then((res) => {
              return res.data.data
          })
          .catch((error) => {
              return error.response.data
          })
    },
}


const PRODUCT = 'client/products'

export const ProductApiService = {
    getBrands() {
        return ApiService.get(PRODUCT + '/brands')
    },
    getBrandBySlug(slug) {
        return ApiService.get(PRODUCT + '/brands/' + slug)
    },
    getListProduct(data) {
        let query = '';
        Object.keys(data).map((key, index) =>
          query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
        return ApiService.get(PRODUCT + query)
    },
    getGroupFeatureProduct() {
        return ApiService.get(PRODUCT + '/groups/feature')
    },
    getCategoryProduct() {
        return ApiService.get(PRODUCT + '/category')
    },

    getGroupProduct() {
        return ApiService.get(PRODUCT + '/groups')
    },

    getDetailGroupProduct(slug, query) {
        return ApiService.get(PRODUCT + '/groups/' + slug + query)
    },

    getCategoryBySlug(slug) {
        return ApiService.get(PRODUCT + '/category/' + slug)
    }
}

const REVIEW = 'client/reviews/product-order/by-product/'

export const ReviewApiService = {
    getListReview(id, query = '') {
        return ApiService.get(REVIEW + id + query, {}, true)
    },
}



const BANNER = 'banners';

export const BannerApiService = {
    getBannerByType(type) {
        return ApiService.get(BANNER + '/' + type);
    }
}

const PARTNER = 'clients/partners';

export const PartnerApiService = {
    get() {
        return ApiService.get(PARTNER);
    }
}

const SERVICES = 'client/services';
export const ServiceApiService = {
    get() {
        return ApiService.get(SERVICES + '?limit=4');
    }
}

const DEAL = 'client/deal';
export const DealApiService = {
    getDealHomepage() {
        return ApiService.get(DEAL + '/home-page');
    },
    getBySlug(slug, query) {
        return ApiService.get( DEAL + `/${slug}/products-paginate` + query)
    }
}

const NEWS_HOME_PAGE = 'client/news/home-page'
export const NewsHomePageService = {
    getNews() {
         return ApiService.get(NEWS_HOME_PAGE + `?limit=${PER_PAGE_NEWS_HOME_PAGE}`);
    }
}

const NEWS = 'client/news'
export const NewsService = {
    getDetail(slug) {
        return ApiService.get(NEWS + `/${slug}`)
    },

    getSameCatalog(id) {
        return ApiService.get(NEWS + '/' + id + '/same-catalog')
    },
    getNews(slug = 'all', params = {}) {
       return ApiService.get(NEWS + '/catalogs/' + slug, params)
    },
    getCatalogs() {
        return ApiService.get(NEWS + '/catalogs')
    }
}

const STORIES = 'client/stories'
export const StoreApiService = {
    getStories() {
        return ApiService.get(STORIES);
    }
}

const WEBSITE = 'client/website'
export const WebsiteApiService = {
    getWebsite() {
        return ApiService.get(WEBSITE);
    }
}

const CART = 'client/cart'
export const CartApiService = { 
    getCart (data = {}){
            return ApiService.get(CART, data ,true);
    },
    getCartNotAuth (data = {}){
        delete axios.defaults.headers.common["Authorization"];
        return ApiService.get(`${CART}?session_id=${parseInt(localStorage.getItem('session_id'))}`, data ,false);
    },
    postCart(dataCart) {
        return ApiService.post(CART, {
            products: dataCart
        } ,true);
    },
    postCartNotAuth(dataCart) {
        delete axios.defaults.headers.common["Authorization"];
        return ApiService.post(CART, {
            products: dataCart,
            session_id: parseInt(localStorage.getItem('session_id'))
        }, false);
    },
}

const ADDRESS = 'client/users/address'

export const AddressApiService = {
    getList (){
        return ApiService.get(ADDRESS, {} ,true);
    },
    store (data = {}){
        return ApiService.post(ADDRESS, data ,true);
    },
}

const SHIPPING_FEE = 'client/shipping-fee'
export const ShippingFee = {
    getShippingFee (idProvince, idDistrict){
        return ApiService.get(SHIPPING_FEE + '/province/' + idProvince + '/district/' + idDistrict, {});
    },
}

const ORDER = 'client/create-order'
export const OrderApiService = {
    create (params){
        return ApiService.post(ORDER, params, true);
    },  
    createNotAuth (params){
        delete axios.defaults.headers.common["Authorization"];
        return ApiService.post(ORDER, params, false);
    }
}
const ORDER_CLIENT = 'client/orders'
export const OrderClientApiService = {
    getList (query){
        return ApiService.get(ORDER_CLIENT + query, {}, true);
    },
    getDetail(id){
        return ApiService.get(ORDER_CLIENT + '/' + id, {}, true)
    }
}

const REVIEW_ORDER_CLIENT = 'client/reviews/product-order/'
export const ReviewOrderClientApiService = {
    review (data = {} , idOrder){
        return ApiService.post(REVIEW_ORDER_CLIENT + idOrder, data, true);
    },
}


const PRODUCT_FAVORITE = 'client/users/product-favorite'
export const ProductFavoriteApiService = {
    getListFavorite (){
        return ApiService.get(PRODUCT_FAVORITE + '/list', {}, true);
    },
    getAllListFavorite (){
        return ApiService.get(PRODUCT_FAVORITE + '/list-no-paginate', {}, true);
    },
    postFavorite(data){
        return ApiService.post(PRODUCT_FAVORITE + '/store', data, true);
    }
}
const PRODUCT_SEEN = 'client/users/product-seen'
export const ProductSeenService = {
    storeProductSeen(data = {}) {
        return ApiService.post(PRODUCT_SEEN + '/store', data, true);
    },
    get(data = {}) {
        return ApiService.get(PRODUCT_SEEN + '/list' , data , true)
    }
}

const CITIES = 'cities'
export const CityApiService = {
    getListCity(){
        return ApiService.get(CITIES)
    },
}

const DISTRICT = 'districts'
export const DistrictApiService = {
    getListDistrictByCityId(cityId){
        return ApiService.get(DISTRICT + '?city_id=' + cityId)
    }
}


const WARD = 'wards'
export const WardApiService = {
    getListWardByDistrictId(districtId){
        return ApiService.get(WARD + '?district_id=' + districtId)
    }
}
const FAQ = 'client/faq'
export const FaqService = {
    store(data) {
        return ApiService.post(FAQ, data)
    },
    getList(params = {}) {
        return ApiService.get(FAQ, params, true)
    },
    answer(id, data) {
        return ApiService.post(FAQ + `/${id}/answer`, data, true)
    }
}

const COUPON = 'client/coupons'
export const CouponApiService = {
    getByOrder(data = {}) {
        return ApiService.get(COUPON + '/by-orders', data)
    },
    getByProduct(id, data = {}) {
        return ApiService.get(COUPON + '/by-products/' + id, data)
    },

    getList(query = '') {
        return ApiService.get(COUPON + query, {}, true)
    },

}

const PRESCRIPTION = 'client/prescription'
export const PrescriptionApiService = {
    store(data = {}) {
        return ApiService.post(PRESCRIPTION, data, true)
    },
    getList(query) {
        return ApiService.get(PRESCRIPTION + query, {}, true)
    },
}

const POINT = 'client/user-log'
export const PointApiService = {
    history(query) {
        return ApiService.get(POINT + query, {}, true)
    },
}