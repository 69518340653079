import React, {useEffect, useState} from 'react';
import BreadCrumb from "../breadcrumb/Breadcrumb";
import arrowRight from "../../../assets/img/Vector 9 (1).svg";
import arrhoverRight from "../../../assets/img/Vector 9 (1) copy.svg";
import arrowLeft from "../../../assets/img/Vector 9.svg";
import arrhoverLeft from "../../../assets/img/Vector 9 copy.svg";
import LazyLoad from "react-lazyload";
import $ from "jquery";
import './news.scss';
import BannerFooter from "../banner-footer/BannerFooter";
import {useHistory, useParams} from "react-router-dom";
import {NewsService} from "../../../ultils/EndPoint";
import {formatDateTime} from "../../../helpers/Function";

function DetailNews() {
    const {slug} = useParams()
    const [newDetail, setNewDetail] = useState({});
    const [newsSameCatalog, setNewsSameCatalog] = useState([]);
    let history = useHistory();
    useEffect(async () => {
        let e = await NewsService.getDetail(slug)
        if (e.status) {
            setNewDetail(e.data)
            let catalog = e.data.new_catalog_relation

            let newsSameCatalog = await NewsService.getSameCatalog(e.data.id)
            if (newsSameCatalog.status) {
                setNewsSameCatalog(newsSameCatalog.data)
            }
        }
    }, [slug])

    $(function (){
        if (newsSameCatalog.length) {
            $('.branch-news').not('.slick-initialized').slick({
                dots: false,
                infinite: true,
                speed: 500,
                autoplay:true,
                slidesToShow: 4,
                slidesToScroll: 1,
                prevArrow: '.arrowLeft',
                nextArrow: '.arrowRight',
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    },

                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 475,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },


                ]
            })
        }
        var imgNews = $('.img_news').find('img');
        var imgDetailNews = imgNews.innerWidth()
        if(600 < imgDetailNews < 3000) {
            imgNews.addClass('auto')
        }
    })
    return (
        <div>
            <div className="container">
                <div className="row">
                    <BreadCrumb from={'Trang chủ'} to={"Tin tức"} toNameSlug={"tin-tuc-danh-muc"} toSlug={"all"} detail={newDetail.name}/>
                    <div className="container">
                        <div className="row">
                            <div className="content_detail_news" style={{width:'100%'}}>
                                <div className="d-flex justify-content-between">
                                    <h5>{newDetail.new_catalog_relation && newDetail.new_catalog_relation.length ?  newDetail.new_catalog_relation[0].name : ""}</h5>
                                    <span className="datetimer">{ formatDateTime(newDetail.updated_at) }</span>
                                </div>
                                <h1>{newDetail.name}</h1>

                                <div className="news-description short-description">
                                    <p>{newDetail.description}</p>
                                </div>
                                <div className="img_news" dangerouslySetInnerHTML={{__html: newDetail.content}}/>
                            </div>
                            <div className="branch-wapper news_slide w-100 mt-0 mb-0">
                                <div className="branch-title">
                                    <h2>Tin tức cùng danh mục</h2>
                                    <div className="button_slide d-flex">
                                        <p className="arrow arrowLeft "><img alt="" className="arrow-1" src={arrowRight}></img> <img alt=""
                                                                                                                                     className="arrow-2"
                                                                                                                                     src={arrhoverRight}></img>
                                        </p>
                                        <p className="arrow arrowRight ml-2"><img alt="" className="arrow-3" src={arrowLeft}></img> <img alt=""
                                                                                                                                         className="arrow-4"
                                                                                                                                         src={arrhoverLeft}></img>
                                        </p>
                                    </div>
                                </div>
                                <div className="position-relative">
                                    <div className="branch-news">
                                        {newsSameCatalog.length && newsSameCatalog.map((news, key) => {
                                            return (
                                                <div key={key} className="child" onClick={() => history.push(`/tin-tuc/${news.slug}`)}>
                                                    {/* <LazyLoad height={200}> */}
                                                        <div className="discount-wapper">
                                                            <img alt="image_news" src={news.image}></img>
                                                        </div>
                                                    {/* </LazyLoad> */}
                                                    <div>
                                                        <p style={{cursor: "pointer"}}>{news.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BannerFooter/>
        </div>
    )
}

export default DetailNews;
