import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import avatar from '../../../assets/img/logo_admin_citimed.png';
import {useSelector} from "react-redux";
import {
    formatDateNew,
    onlyNumber,
    removeExtraSpace,
    validatePhone
} from "../../../helpers/Function";
import isEmpty from "validator/lib/isEmpty";
import {FaqService} from "../../../ultils/EndPoint";
import {toast} from "react-toastify";
import {Pagination} from "antd";
import Login from "../../../pages/auth/login";
function Comment(props) {
    const [questions, setQuestions] = useState([])
    const auth = useSelector(state => state.AuthMeRoot.auth)
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        content: ""
    })
    const [validationMsg, setValidationMsg] = useState({});
    const [currentPage, setcurrentPage] = useState(1);
    const [showLogin, setShowLogin] = useState(false);
    const [total, setTotal] = useState();
    const customersPerPage = 5;
    const userAuthToken = localStorage.getItem('User-token');
    const setCurrentPage = async (page) => {
        setcurrentPage(page)
        if (props.product.id) {
            getQuestions(page)
        }
    }
    useEffect(() => {
        if (auth) {
            setFormData({
            name : auth.Name,
            phone : auth.Phone,
            content : ""
            })
        }
        if (props.product.id) {
            getQuestions(1)
        }
    },[auth, props.product.id] )
    async function getQuestions(page) {
        let res = await FaqService.getList({
            model_id: props.product.id,
            model_type: 'product',
            page: page,
            per_page : customersPerPage
        })
        if (res.status) {
            setQuestions(res.data.records|| [])
             setTotal(res.data.total_record || 0)
        }
    }
     useEffect(() => {
         $('input[name="inputForNumber"]').keypress(function (event) {
                 let value = $('input[name="inputForNumber"]').val()
                 onlyNumber(event, value)
             }
         );
         $('.reply_comment').hide()
         $('.input_reply_total').hide()
         $('.hide-comment').hide()
         $('.btn_rep_childs').off('click').on('click', function () {
            if (userAuthToken) {
                let value = $(this).val()
                $('.reply-total-' + value).toggle();
            }
         })
         $('.btn-show').off('click').on('click', function () {
             let value = $(this).val()
             $('.hide-comment-' + value).toggle()
             $('.reply_comment-' + value).toggle();
             $('.btn-show-' + value).toggle();
             // $(".btn_rep_childs").attr("disabled", true);
         })
         $('.btn-hide').off('click').on('click', function () {
            let value = $(this).val()
             $('.reply_comment-' + value).toggle();
             $('.btn-show-' + value).toggle();
             $('.hide-comment-' + value).toggle()
             // $(".btn_rep_childs").attr("disabled", false);
         })
    })
    const handleChange = async (evt, key) => {
        let value =  key === "content" ? evt.target.value : removeExtraSpace(evt.target.value)
         setFormData({
            name : key === "name" ? value :formData.name ,
            phone : key === "phone" ? value : formData.phone,
            content : key === "content" ? value : formData.content
         })
    }
    const validateAll = (data) => {
        const msg = {};

        if(data.name.length > 255){
            msg.name = 'Trường họ và tên chỉ tối đa 255 kí tự.Vui lòng nhập lại.'
            $('.form-name').addClass('red_blur')
        }else if(data.name === '') {
            msg.name = 'Vui lòng nhập họ và tên'
            $('.form-name').addClass('red_blur')
        }

        if(isEmpty(data.phone)) {
            msg.phone = 'Vui lòng nhập số điện thoại '
            $('.form-phone').addClass('red_blur')
        } else if(!validatePhone(data.phone)) {
            msg.phone = 'Số điện thoại không đúng định dạng '
            $('.form-phone').addClass('red_blur')
        }

        if(isEmpty(data.content)) {
            msg.content = 'Vui lòng nhập câu hỏi'
            $('.form-content').addClass('red_blur')
        }

        setValidationMsg(msg)
        return Object.keys(msg).length <= 0;
    }
    function resetForm() {
        setValidationMsg({})
        setFormData({
            name : auth.Name,
            phone : auth.Phone,
            content : ""
     })
        $('.form-email').removeClass('red_blur')
        $('.form-phone').removeClass('red_blur')
        $('.form-content').removeClass('red_blur')
    }
    async function question() {
       let isLogin = checkLogin()
        if (isLogin) {
            let valid = validateAll(formData)
            if (valid) {
                formData.model_type = 'product'
                formData.model_name = 'product'
                formData.model_id = props.product.id
                let res = await FaqService.store(formData)
                if (res.status) {
                    resetForm()
                    toast.success('Thêm câu hỏi thành công !')
                    getQuestions(1)
                }
            }
        }
    }
    function checkLogin() {
        if (!userAuthToken) {
            setShowLogin(true)
            return false
        }
        return true
    }
    async function createAnswer(event, question, index) {
        if (!userAuthToken) {
            setShowLogin(true)
            return false
        }
        let answer = event.target.value
        let res = await FaqService.answer(question.id, {
            content : answer.trim()
        })
        if (res.status) {
             $('.answer-' + index).val("")
             $('.reply_comment-' + index).show()
             $(".btn-show-" + index).show()
             toast.success('Trả lời câu hỏi thành công !')
             await getQuestions(1)
        }
    }

    return (
    <div>
        <div className="ratings faq" id="faq">
            <div className="title_ratings">
                <h1>Hỏi đáp sản phẩm</h1>
            </div>
            <div className="content_ratings">
                <h5>Bạn có câu hỏi dành cho sản phẩm <strong>{props.product.name} ?</strong></h5>
                { userAuthToken ?
                <form action="">
                    <div className="form-group" style={{'display': 'none'}}>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <label htmlFor="">Họ và tên</label>
                                <input type="text" placeholder="Nhập họ và tên" readOnly={!!auth.Name} value={formData.name || ''}
                                onChange={(event) => handleChange(event, 'name')} className="form-control form-name"/>
                                <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.name}</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <label htmlFor="">Số điện thoại</label>
                                <input type="text" name="inputForNumber" placeholder="Số điện thoại" readOnly={!!auth.Phone} value={formData.phone || ''}
                                onChange={(event) => handleChange(event, 'phone')} className="form-control form-phone"/>
                                <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.phone}</p>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="">Câu hỏi</label>
                                <textarea name="" id="" cols="30" rows="4" className="form-control form-content" placeholder="Nhập câu hỏi bạn cần giải đáp ở đây nhé"
                                value={formData.content || ''} onChange={(event) => handleChange(event, 'content')}/>
                                <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.content}</p>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="button" className="btn btn_faq" onClick={question}>Đặt câu hỏi</button>
                    </div>
                </form>
                  : <h5>Vui lòng <strong style={{'color':'blue', 'cursor':'pointer'}} onClick={() => setShowLogin(true)}>đăng nhập</strong> để có thể đặt câu hỏi cho chúng tôi nhé!</h5> }
            </div>
            <Login show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
        </div>
        {questions.map((question, index ) => (
            <div key={index}>
                <div className="content_comment_list" >
            <div className="title_content_ratings d-flex">
                <img src={question.user.avatar} alt={question.model.name} className="avatar"/>
                <h5>{question.user.name}</h5>
                <span>{formatDateNew(question.created_at)}</span>
            </div>
            <div className="comment_ratings">
                <p>{question.content}</p>
                <button type="button" className="btn-rep btn_rep_childs" value={index} onClick={checkLogin}>Trả lời</button>
                {question.faq_answers && question.faq_answers.length ? (
                <button type="button" className={"btn-show btn-show-" + index} value={index}>Hiển thị bình luận ({question.faq_answers.length})</button>) : ''}
            </div>
            {question.faq_answers && question.faq_answers.length ? question.faq_answers.map((answer, keyAnswer) => (
                <div key={keyAnswer}>
                    <div className={"reply_comment reply_comment-" + index} >
                        <div className="childs_reply_comment">
                            <div className="title_content_ratings d-flex">
                                <img src={answer.is_admin ? avatar : answer.user.avatar} alt={answer.user.name} className="avatar"/>
                                {answer.is_admin ? <h5 className="admin">Admin {process.env.REACT_APP_NAME}</h5> : <h5>{answer.user.name}</h5> }
                                <span>{formatDateNew(answer.created_at)}</span>
                            </div>
                            <div className="comment_ratings">
                                <p>{answer.content} </p>
                            </div>
                        </div>
                            {/*    <div className="input_reply" style={{paddingTop: 10 + 'px'}}>*/}
                            {/*        <input type="text" className={"form-control mt-10 answer-"+  index}*/}
                            {/*        placeholder="Nhập bình luận ở đây" onKeyPress={event => {if (event.key === 'Enter') {createAnswer(event, question, index)}}}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                </div>
            )) : ""}
                    {question.faq_answers && question.faq_answers.length ? (
                    <div className={"comment_ratings hide-comment hide-comment-" + index}>
                        <div className="d-flex mt-16">
                            {/*<button type="button" className="btn-rep" id="btn_rep_childs">Trả lời</button>*/}
                            <div className="d-flex btn_aciton_comment">
                                <button type="button" className="btn-hide" value={index}>Ẩn bình luận</button>
                            </div>
                        </div>
                    </div>) : "" }
            <div className={"input_reply_total reply-total-"+ index} style={{paddingTop: 10 + 'px'}}>
                <input type="text" className={"form-control answer-"+  index} placeholder="Nhập bình luận ở đây"  onKeyPress={event => {if (event.key === 'Enter') {createAnswer(event, question, index)}
              }}/>
            </div>
        </div>
        </div>
        ))}
        {total <= customersPerPage ? '' :
                                  <Pagination
                                    defaultCurrent={currentPage}
                                    onChange={(value) => {document.getElementById('faq').scrollIntoView({behavior: 'smooth'}); setCurrentPage(value) }}
                                    total={total}
                                    current={currentPage}
                                    defaultPageSize={customersPerPage}
                                    showSizeChanger= {false}
                                  />
                                }
        </div>
    )
}

export default Comment
