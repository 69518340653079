import React, {useEffect, useState} from 'react';
import icon_standard1 from '../../../assets/img/profile/badge (1) 2.png';
import icon_standard from '../../../assets/img/profile/badge (1) 1.png';
import {Button, Modal, ProgressBar} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {ApiService, CartApiService, ProductApiService} from "../../../ultils/EndPoint";
import {Pagination} from "antd";
import {GetAllDataCart} from "../../../actions/Cart";
import Form from "react-bootstrap/Form";
import {RANK} from "../../../helpers/constant";
import {websiteActions} from "../../../actions";
import {useHistory} from "react-router-dom";


function BuyHistoryPoint() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [showDanger, setShowDanger] = useState(false);
    const [showOutStock, setShowOutStock] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [product, setProduct] = useState({id: 0});
    const handleClose = () => setShow(false);
    const handleCloseDanger = () => setShowDanger(false);
    const handleCloseOutStock = () => setShowOutStock(false);
    const [products, setProducts] = useState([])
    const [dataPaginate, setDataPaginate] = useState({
        "currentPage" : 1
    })
    const [auth, setAuth] = useState({})

    const perPage = 10;
    const [infoRank, setInfoRank] = useState({})

    const [pointSelect, setPointSelect] = useState(0)

    const handleShow = () => {
        setShow(true)
    };
    const handleShowDanger = () => {
        setShowDanger(true)
    };
    const handleShowOutStock = () => {
        setShowOutStock(true)
    };
    const handleCloseSuccess = () => {
        setShowSuccess(false)
    };
    const handleGoToCart = async () => {
        await dispatch(websiteActions.setFooter(false))
        history.push('/cart')
    }

    const showPopupConfirm = (v) => {
        setPointSelect(v.point);
        setProduct(v);
        handleShow()
    }
    const state = useSelector(state => state.todoProduct);
    var data = [];
    state.cartData.products && state.cartData.products.map(cart =>
      {data.push({
          quantity: cart.quantity,
          product_id: cart.id,
          coupon_id: cart.coupon_id,
          selected: true,
      })
      }
    )

    const handleAddToCart = () => {
        handleClose()
        if (product.point > auth.Point) {
            handleShowDanger()
            return;
        }
        if (product.product_stock <= 0){
            handleShowOutStock()
            return;
        }
        var result = data.find(obj => {
            return obj.product_id === product.id
        })

        if(result){
            data && data.map(check => {
                if(check.product_id === product.id){
                    check.quantity += 1
                }
            })
            CartApiService.postCart(data).then(res => {
                if(res.status === true){
                    setShowSuccess(true)
                    dispatch(GetAllDataCart(res.data));
                }
            })

        }else {
            CartApiService.postCart([{
                product_id : product.id,
                quantity : 1,
                selected : true,
            }].concat(data)).then(res => {
                if(res.status === true){
                    setShowSuccess(true)
                    dispatch(GetAllDataCart(res.data));
                }
            })
        }
    }

    function getListProduct(data){
        ProductApiService.getListProduct(data).then(r => {
            if (r.status){
                setProducts(r.data.records)
                setDataPaginate({
                    "totalRecord" : r.data.total_record,
                    "totalPage"   : r.data.total_page,
                    "currentPage"   : r.data.page,
                    "perPage"   : r.data.limit,
                })
            }
        })
        ApiService.get('client/me', {}, true).then(r => {
            if (r.status){
                setAuth(r.data)
            }
        })
    }

    function setCurrentPage(page){
        let data = {}
        data.type_id = "[1]"
        data.status = 1
        data.page = page
        data.per_page = perPage
        getListProduct(data)
    }

    const getInfoRank = () => {
        let pointAuth = auth.point_rank_level
        let rank = RANK.filter((value, key) => {
            if ((value.min <= pointAuth && value.max >= pointAuth) || (value.min <= pointAuth && key === RANK.length - 1)){
                return value
            }
        })
        setInfoRank(rank[0])
    }
    useEffect(() => {
        getInfoRank()
    }, [auth])

    useEffect(() => {
        let data = {}
        data.type_id = "[1]"
        data.per_page = perPage
        getListProduct(data)
    }, [])
    return (
        <div className="content_purchase">
            <div className="changePass-wapper">
                <h1>Đổi điểm</h1>
                <div className="step_history_point d-flex justify-content-between">
                    <div className="grid text-center">
                        <img src={icon_standard} alt=""/>
                        <span>{infoRank ? infoRank.rank_current : ''}</span>
                    </div>
                    <div className="bg_step">
                        <span>{auth.point_rank_level}{infoRank && infoRank.max ? "/" : ""}{infoRank ? infoRank.max : ''}</span>
                        <strong>
                            <ProgressBar max={infoRank ? infoRank.max : auth.point_rank_level} variant="success" now={auth.point_rank_level} />
                        </strong>
                    </div>
                    <div className="grid text-center">
                        {infoRank && infoRank.max ? <img src={icon_standard1} alt=""/> : ""}
                        <span>{infoRank ? infoRank.rank_next : ''}</span>
                    </div>
                </div>
                <ul className="list_product_buy_point pl-0">
                    {products? products.map((v,key) =>
                            v.status !== 0 
                            ?  <li key={key}>
                                    <img src={v.medias[0].image} alt=""/>
                                    <div className="grid align-self-center">
                                        <span>{v.name}</span>
                                        <a onClick={() => { showPopupConfirm(v) }}>Đổi sản phẩm với {v.point} C-MED</a>
                                    </div>
                                </li> 
                            : ''
                    ) : ''}
                </ul>
            </div>

            {dataPaginate.totalRecord <= perPage ? '' :
                <Pagination
                    onChange={(value) => setCurrentPage(value)}
                    total={dataPaginate.totalRecord}
                    current={dataPaginate.currentPage}
                    showSizeChanger= {false}
                    defaultPageSize={perPage}
                />
            }
            <Modal className="modal_deleteAdressUser modal_success" show={show} onHide={handleClose}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <p>Bạn có chắc chắn sử dụng {pointSelect} điểm để đổi sản phẩm này</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} variant="secondary" >
                        Không
                    </Button>
                    <Button variant="primary" onClick={() => handleAddToCart()}>
                        Đổi sản phẩm
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal className="modal_deleteAdressUser modal_danger" show={showDanger} onHide={handleCloseDanger}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <p>Bạn không đủ điểm để đổi sản phẩm này</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDanger} variant="secondary" >
                        Đồng ý
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="modal_deleteAdressUser modal_danger" show={showOutStock} onHide={handleCloseOutStock}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <p>Sản phẩm này đã hết hàng, vui lòng quay lại sau!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseOutStock} variant="secondary" >
                        Đồng ý
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSuccess} onHide={handleCloseSuccess} className="modal_deleteAdressUser modal_AddSuccess_Cart">
                <Modal.Header closeButton className="grid">
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="mt-3">
                    <Form>
                        <p className="mb-3">Thêm vào giỏ hàng thành công</p>
                        <div variant="primary" onClick={ async () => {
                            await dispatch(websiteActions.setFooter(false))
                            handleGoToCart()
                            handleCloseSuccess()
                        }} className="form-group mb-0">
                            <button type="button" className="btn btn-submit goto_cart">Xem giỏ hàng</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default BuyHistoryPoint;
