import React, {useEffect, useState} from 'react';
import MegaMenu from "../menu/mega_menu";
import {useDispatch} from "react-redux";
import {ProductApiService} from "../../../ultils/EndPoint";
import {actions} from "../../../actions/Category";
import Login from "../../../pages/auth/login";
import {websiteActions} from "../../../actions";
import {Link, useHistory} from "react-router-dom";
import $ from "jquery";

function Index() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [categories, setCategory] = useState([]);
  const userAuthToken = localStorage.getItem('User-token');
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
      ProductApiService.getCategoryProduct().then(async res => {
         let data = res.data && res.data.filter(item => {
          if (item.status) {
            return item;
          }
        })
        setCategory(data)
        dispatch(actions.setCategoryProduct(data))
      })
  }, [])

  function CheckAuth(link) {
    if (userAuthToken) {
      dispatch(websiteActions.setFooter(true))
      history.push(link)
      return;
    }
    setShowLogin(true)
  }

  const closeHover =  (e) => {
    $(e.target).parent().find('.mega-menu-child').hide()
    dispatch(websiteActions.setFooter(true))
  }

  useEffect(() =>{
    $(function () {
      $(document).ready(function () {
        $('.dropdown-megamenu').mouseenter(function () {
            $(this).find('.mega-menu-child').show()
        });
        $('.dropdown-megamenu').mouseleave(function () {
          $(this).find('.mega-menu-child').hide()
        });
      });
    })
  })
  return (
    <div className="header-bottom">
      <ul>
        <div className="container-fluid">
          <div className="header-row">
            <div className="d-flex menu-header container">
              <li className="d-flex flex-column non-boder-right">
                <Link to="/" onClick={() => {dispatch(websiteActions.setFooter(true))}}>Trang chủ</Link><Link to="/" onClick={() => {dispatch(websiteActions.setFooter(true))}} style={{'color': '#B4840E'}}>{process.env.REACT_APP_NAME}</Link>
              </li>
              {categories && categories.map((category, index) => {
                if (index < 8) {
                  return (<li key={category.id} className="dropdown dropdown-megamenu non-boder-right">
                    <Link to={"/danh-muc/" + category.slug} onClick={closeHover}>{category.name}</Link>
                    {category.childs && category.childs.length ? <MegaMenu childs={category.childs}/> : ''}
                  </li>)
                } else {
                  return ''
                }
              })}
              <li className="d-flex flex-column non-boder-right"><span> Diễn đàn</span> <span
                style={{'color': 'red'}}>CitiCare</span></li>
              <li className="non-boder-left non-boder-right button-upbill">
                <button onClick={(e) => {e.preventDefault(); CheckAuth('/profile/prescription/create')}} type="button" className="btn">Đăng đơn thuốc</button>
              </li>
            </div>
          </div>
        </div>
      </ul>
      <Login  show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
    </div>
  )
}
export default Index