import React, {useState} from 'react';
import LazyLoad from "react-lazyload";
import deleteFavor from "../../../assets/img/vector_x.svg";
import icon_cart from "../../../assets/img/Group 68.svg";
import {finalPrice, formatCurrency, getDiscount} from "../../../helpers/Function";
import { useDispatch, useSelector } from 'react-redux';
import {GetAllDataCart} from '../../../actions/Cart';
import {getImageMedia} from "../../../helpers/Function";
import {CartApiService} from '../../../ultils/EndPoint';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import axios from 'axios';
import {deleteFavorItem} from '../../../actions/FavoriteProduct';
import ENDPOINT from '../../../ultils/EndPoint';
import {useHistory} from "react-router-dom";
import {websiteActions} from "../../../actions";


function ProductFavorite({product}) {
    const history = useHistory()
    const TYPE_PRODUCT_ORDER = 3;
    const dispatch = useDispatch();
    const state = useSelector(state => state.todoProduct);
    const [showStatus, setShowStatus] = useState(false);
    const [message, setMessage] = useState(false);
    const handleCloseStatus = () => {
      setShowStatus(false)
    };
    const showAlert = (message) => {
      setMessage(message);
      setShowStatus(true);
    }

    // set token check 
    const tokenUser = localStorage.getItem('User-token')
    // set show for modal
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
    };

    // set show for modal delete favorite
    const [showDeleteFavor, setShowDeleteFavor] = useState(false);
    const handleCloseFavorite = () => {
        setShowDeleteFavor(false)
    };

    const [loveIdProduct, setLoveIdProduct] = useState('');
    const handleShow = (e) => {
        setShowDeleteFavor(true)
        setLoveIdProduct(e);
    };

    var data = [];
    state.cartData.products && state.cartData.products.map(cart => 
      {data.push({
          quantity: cart.quantity, 
          product_id: cart.id,
          coupon_id: cart.coupon_id,
          selected: true,
      })
      }
    )

    const handleAddToCart = (id) => {
      if(product.product_stock == 0) {
          showAlert('Sản phẩm đã hết hàng!');
          return;
        } else if(product.status == 0) {
          showAlert('Sản phẩm đang ngừng bán!');
          return;
        }
        var result = data.find(obj => {
            return obj.product_id === id.id
          })
  
          if(result){
              data && data.map(check => {
                  if(check.product_id === id.id){
                    check.quantity += 1
                  }
              })
              CartApiService.postCart(data).then(res => {
                  if(res.status === true){
                      setShow(true)
                      dispatch(GetAllDataCart(res.data));
                  }
              })
              
          }else {
              CartApiService.postCart([{
                  product_id : id.id,
                  quantity : 1,
                  selected : true,
              }].concat(data)).then(res => {
                  if(res.status === true){
                      setShow(true)
                      dispatch(GetAllDataCart(res.data));
                  }
              })
          }
    }

    const handleGoToCart = async () => {
        await dispatch(websiteActions.setFooter(false))
        await history.push('/cart')
    }
 
    // post data favorite love
    const handleDeleteOfFavarits = (e) => {
        const data ={
            product_id : e
        }
        axios.delete(`${ENDPOINT.FAVORITE_PRODUCT}/delete`, {
        headers: {
            Authorization: 'Bearer ' + tokenUser
        }, data: data
        })
        .then(function (response) {
            if(response.status === 200) {
                dispatch(deleteFavorItem(e))
            }
        })
        .catch(err =>
            console.log(err)
        )
    }

    const handleToDetail = (slug) => {
        history.push(`/san-pham/${slug}`)
    }

    return (
      <div>
          <div className="child">
              <LazyLoad height={200}>
                  <div className="discount-wapper">
                    {product.medias !== null ? <img style={{cursor: 'pointer'}} onClick={()=> handleToDetail(product.slug)} src={getImageMedia(product.medias)} alt={product.name}/> : null}
                      { ((product.discount > 0 && product.discount < product.price) || (product.deal && product.deal.id)) > 0 ?
                        <p className="discount">{getDiscount(product)}%</p>
                        : ''  }
                      <div onClick={()=> handleShow(product.id)} className="loveCount mr-3">
                        <img src={deleteFavor} style={{'width': '10px', 'height': '11px'}} alt="love-icon"></img>
                      </div>
                      
                  </div>
              </LazyLoad>
              <div>
                <p style={{cursor: 'pointer'}} onClick={()=> handleToDetail(product.slug)}>{product.name}</p>
                  <div className="price-product">
                      { product.type_id === TYPE_PRODUCT_ORDER ?
                        <div className="d-flex justify-content-center align-items-baseline">
                          <h2>Liên hệ</h2>
                        </div>
                        : ((product.discount > 0 && product.discount < product.price) || (product.deal && product.deal.id) ?
                        <div className="d-flex justify-content-center align-items-baseline">
                          <h2>{formatCurrency(finalPrice(product))}đ</h2>
                            <h4 className="ml-2">{formatCurrency(product.price)}đ</h4>
                        </div>
                        :
                        <div className="d-flex justify-content-center align-items-baseline">
                            <h2>{formatCurrency(product.discount)}đ</h2>
                        </div>)
                      }
                    {product.type_id !== TYPE_PRODUCT_ORDER ?
                      <div  onClick={() => handleAddToCart(product)}   className="cart-hover mr-3">
                          <img alt="" src={icon_cart} style={{'width': '16px', 'height': '16px'}}></img>
                      </div> : '' }
                </div>
            </div>
        </div>
        <Modal className="modal_deleteAdressUser" show={showStatus} onHide={handleCloseStatus}>
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            <p>{message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseStatus}>
              Đóng
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show} onHide={handleClose} className="modal_deleteAdressUser modal_AddSuccess_Cart">
              <Modal.Header closeButton className="grid">
                  <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body className="mt-3">
                  <Form>
                      <p className="mb-3">Thêm vào giỏ hàng thành công</p>       
                      <div variant="primary" onClick={async () => {
                          await dispatch(websiteActions.setFooter(false))
                          handleGoToCart()
                          handleClose()
                      }} className="form-group mb-0">
                          <button type="button" className="btn btn-submit goto_cart">Xem giỏ hàng</button>
                      </div>
                  </Form>
              </Modal.Body>
          </Modal>
          <Modal className="modal_deleteAdressUser" show={showDeleteFavor} onHide={handleCloseFavorite}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body  closeButton>
                        <p>Bạn có chắc chắn muốn xóa sản phẩm này khỏi danh sách ?</p>               
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseFavorite}>
                            Không
                        </Button>
                        <Button variant="primary" onClick={() => {
                           handleDeleteOfFavarits(loveIdProduct)
                           handleCloseFavorite()
                        }}>
                            Xoá sản phẩm
                        </Button>
                    </Modal.Footer>
                </Modal>

      </div>
      
    )
}
export default ProductFavorite;